import { StandardTextFieldProps, TextField, styled } from "@mui/material";
import React from "react";

interface ISearchBarProps extends StandardTextFieldProps {
  isMobile: boolean;
  searchExpanded: boolean;
}

export const StyledTextBox = styled(TextField)<ISearchBarProps>(props => ({
  transition: ".5s ease-in-out",
  "& .MuiInputBase-formControl": {
    backgroundColor: "#fff",
    border: "1px solid #c9c9c9",
    borderRadius: "20px",
    color: "#202223",
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "'Inter', sans-serif",
    lineHeight: "20px",
    height: "30px",
    minWidth: "230px",
    padding: "5px 15px",
    width: "210px",
    transition: ".5s ease-in-out",
  },
  "& input::placeholder": {
    color: "rgba(32, 34, 35, 0.5)",
  },

  "& fieldset": {
    display: "none",
  },

  ...(props.isMobile && {
    "& .MuiInputBase-formControl": {
      backgroundColor: "#fff",
      border: "1px solid #c9c9c9",
      borderRadius: "20px",
      color: "#202223",
      fontSize: "14px",
      fontWeight: "400",
      fontFamily: "'Inter', sans-serif",
      lineHeight: "20px",
      height: "30px",
      padding: "5px 15px",
      transition: ".5s ease-in-out",

      minWidth: "42px",
      width: "42px",
    },
    "& input": {
      display: "none",
    },
  }),

  "@media screen and (max-width: 768px)": {
    minWidth: "unset",
    marginLeft: "0",
    display: "flex",
    alignItems: "end",
    width: "max-content",
    height: "max-content",
  },

  ...(props.isMobile &&
    props.searchExpanded && {
      "&": {
        width: "100%",
      },
      "& input": {
        display: "block",
      },
      "& .MuiInputBase-formControl": {
        backgroundColor: "#fff",
        border: "1px solid #c9c9c9",
        borderRadius: "20px",
        color: "#202223",
        fontSize: "14px",
        fontWeight: "400",
        fontFamily: "'Inter', sans-serif",
        lineHeight: "20px",
        height: "30px",
        padding: "5px 15px",
        transition: ".5s ease-in-out",

        minWidth: "100%",
        width: "100%",
      },
    }),
}));

export const SearchInput = React.forwardRef<HTMLDivElement, ISearchBarProps>(
  ({ isMobile, searchExpanded, ...props }: ISearchBarProps, ref): JSX.Element => {
    return <StyledTextBox isMobile={isMobile} ref={ref} searchExpanded={searchExpanded} {...props} />;
  }
);
