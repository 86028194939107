import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { prefixClassName } from "@smartrr/shared/components/primitives";
import React from "react";

export interface IStyledButtonProps extends ButtonProps {
  /** `filled` -> Dark background */
  filled?: boolean;

  /** Displays loader within button*/
  isLoading?: boolean;

  /** Text shown after button is clicked  */
  confirmText?: string;

  /** Toggle for text after button is clicked */
  showConfirm?: boolean;

  /** Toggle for depressed button state */
  selected?: boolean;

  /** Toggle for disabled button state */
  disabled?: boolean;
}

const ButtonWrapper = styled(Button)<IStyledButtonProps>`
  background-color: ${props => props.theme.modernSecondaryButtonColor};
  border-color: ${props => props.theme.modernSecondaryButtonBorderColor};
  border-radius: ${props => props.theme.modernButtonBorderRadius}px;
  border-style: solid;
  border-width: ${props => props.theme.modernButtonBorderWidth}px;
  color: ${props => props.theme.modernSecondaryButtonFontColor};
  font-family: inherit;

  font-size: ${props => props.theme.modernButtonCTAFontSize}px;
  font-weight: ${props => props.theme.modernButtonCTAFontWeight};
  height: ${props => props.theme.modernButtonBorderHeight}px;
  letter-spacing: ${props => props.theme.modernButtonCTAFontLetterSpacing}px;
  line-height: ${props => props.theme.modernButtonCTAFontLineHeight}px;

  margin-top: 15px;
  text-transform: none;
  width: 100%;

  &:hover {
    background-color: ${props => props.theme.modernSecondaryButtonColor};
    box-shadow: 0px 0px 5px ${props => props.theme.modernSecondaryButtonColor};
  }
  :active,
  :focus {
    background-color: ${props => props.theme.modernSecondaryButtonColor};
  }

  ${props =>
    props.disabled &&
    `
    pointer-events: none;
    opacity: 50%;
    &:hover {
      cursor: not-allowed;
    };
`}

  ${props =>
    props.filled &&
    `
   background-color: ${props.theme.modernPrimaryButtonColor};
    border-color: ${props.theme.modernPrimaryButtonBorderColor};
    color: ${props.theme.modernPrimaryButtonFontColor};
    :hover,:active,:focus {
      box-shadow: 0px 0px 5px ${props.theme.modernPrimaryButtonColor};
      background-color: ${props.theme.modernPrimaryButtonColor};
`}
    
     ${props =>
    props.selected &&
    `
     background-color: #f4f4f4;
    :hover {
      background-color: #f4f4f4;
    }
    `}
    ${props =>
    props.showConfirm &&
    `
   pointer-events: none;
    opacity: 70%;
    `} /* 
  ...(props.selected && {
    backgroundColor: #f4f4f4;
    :hover: {
      backgroundColor: #f4f4f4;
    };
  });

  ...(props.showConfirm && {
    pointerEvents: none;
    opacity: 70%;
  }); */
`;

export const OAButton = ({
  children,
  filled,
  isLoading,
  showConfirm,
  confirmText,
  selected,
  disabled,
  sx,
  ...props
}: IStyledButtonProps): JSX.Element => {
  return (
    <ButtonWrapper
      className={prefixClassName(filled ? "primary-button" : "secondary-button")}
      filled={filled}
      isLoading={isLoading}
      showConfirm={showConfirm}
      selected={selected}
      disabled={disabled}
      {...props}
      sx={sx}
    >
      {isLoading ? <CircularProgress /> : showConfirm ? confirmText : children}
    </ButtonWrapper>
  );
};
