import React from "react";

export const PayPal = (): JSX.Element => {
  return (
    <svg width="62" height="15" viewBox="0 0 62 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51009 3.53125H9.74111C12.0128 3.53125 12.869 4.67967 12.733 6.37208C12.5165 9.16253 10.8291 10.7038 8.59269 10.7038H7.46442C7.15717 10.7038 6.95065 10.9053 6.87006 11.4594L6.39156 14.6578C6.36133 14.8643 6.25052 14.9852 6.08934 15.0003H3.42984C3.178 15.0003 3.09237 14.8089 3.15785 14.3959L4.7747 4.13568C4.84018 3.72769 5.06181 3.53125 5.51009 3.53125Z"
        fill="#009EE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.876 3.34C25.3015 3.34 26.6212 4.11568 26.4398 6.04483C26.2233 8.33663 24.9942 9.60594 23.055 9.61097H21.3626C21.1208 9.61097 21 9.80741 20.9395 10.2154L20.6121 12.2957C20.5617 12.6079 20.4006 12.7641 20.1638 12.7641H18.5873C18.3354 12.7641 18.2498 12.6029 18.3052 12.2453L19.6047 3.8991C19.6702 3.48607 19.8213 3.33496 20.1034 3.33496H23.876V3.34ZM21.3072 7.80775H22.5916C23.3925 7.77753 23.9264 7.22347 23.9818 6.22112C24.012 5.60158 23.594 5.15833 22.9291 5.16337L21.7202 5.1684L21.3072 7.80775ZM30.7212 12.1294C30.8673 11.9985 31.0134 11.933 30.9932 12.0942L30.9429 12.482C30.9177 12.6835 30.9983 12.7893 31.1846 12.7893H32.5849C32.8216 12.7893 32.9375 12.6936 32.9929 12.3309L33.8542 6.91622C33.8995 6.64422 33.829 6.51326 33.6225 6.51326H32.0812C31.9402 6.51326 31.8747 6.58882 31.8394 6.80037L31.784 7.1328C31.7538 7.30406 31.6732 7.33932 31.6027 7.16303C31.3408 6.54348 30.6759 6.26645 29.7441 6.29164C27.5832 6.33697 26.1326 7.97397 25.9764 10.0744C25.8556 11.7013 27.0191 12.9756 28.5554 12.9756C29.6685 12.9706 30.1672 12.6432 30.7212 12.1294ZM29.5476 11.2983C28.6208 11.2983 27.9711 10.5579 28.1071 9.64623C28.2431 8.73958 29.1094 7.99412 30.0412 7.99412C30.9731 7.99412 31.6178 8.73455 31.4818 9.64623C31.3458 10.5529 30.4795 11.2983 29.5476 11.2983ZM36.5892 6.49311H35.1688C34.8767 6.49311 34.7558 6.7097 34.8515 6.9817L36.6144 12.1395L34.8868 14.5925C34.7407 14.799 34.8565 14.9854 35.058 14.9854H36.6547C36.6749 14.9854 36.69 14.9904 36.7101 14.9904C36.8814 14.9904 37.0375 14.8997 37.1282 14.7537L42.5479 6.9817C42.7142 6.74496 42.6386 6.49311 42.3616 6.49311H40.8505C40.5936 6.49311 40.4878 6.59385 40.3418 6.81044L38.0802 10.0844L37.0728 6.80037C37.0123 6.59889 36.8663 6.49311 36.5892 6.49311Z"
        fill="#113984"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.1837 3.34C49.6091 3.34 50.9288 4.11568 50.7475 6.04483C50.5309 8.33663 49.3019 9.60594 47.3626 9.61097H45.6702C45.4285 9.61097 45.3076 9.80741 45.2471 10.2154L44.9197 12.2957C44.8694 12.6079 44.7082 12.7641 44.4714 12.7641H42.8949C42.643 12.7641 42.5574 12.6029 42.6128 12.2453L43.9174 3.8991C43.9829 3.49111 44.134 3.33496 44.416 3.33496L48.1837 3.34ZM45.6199 7.80775H46.9043C47.7052 7.77753 48.2391 7.22347 48.2945 6.22112C48.3247 5.60158 47.9066 5.15833 47.2418 5.16337L46.0329 5.1684L45.6199 7.80775ZM55.0288 12.1294C55.1749 11.9985 55.321 11.933 55.3008 12.0942L55.2505 12.482C55.2253 12.6835 55.3059 12.7893 55.4922 12.7893H56.8925C57.1292 12.7893 57.2451 12.6936 57.3005 12.3309L58.1618 6.91622C58.2071 6.64422 58.1366 6.51326 57.9301 6.51326H56.3939C56.2528 6.51326 56.1873 6.58882 56.1521 6.80037L56.0967 7.1328C56.0665 7.30406 55.9859 7.33932 55.9153 7.16303C55.6534 6.54348 54.9886 6.26645 54.0618 6.29164C51.9009 6.33697 50.4503 7.97397 50.2941 10.0744C50.1732 11.7013 51.3368 12.9756 52.873 12.9756C53.9862 12.9756 54.4849 12.6482 55.044 12.1345L55.0288 12.1294ZM53.8603 11.2983C52.9335 11.2983 52.2837 10.5579 52.4197 9.64623C52.5557 8.73958 53.4221 7.99412 54.3539 7.99412C55.2807 7.99412 55.9305 8.73455 55.7945 9.64623C55.6585 10.5529 54.7871 11.2983 53.8603 11.2983ZM60.3126 12.7943H58.6957C58.6907 12.7943 58.6907 12.7943 58.6857 12.7943C58.5799 12.7943 58.4943 12.7087 58.4943 12.6029C58.4943 12.5928 58.4943 12.5828 58.4943 12.5777L59.9147 3.58681C59.9449 3.46088 60.0557 3.37022 60.1816 3.37022H61.7985C61.8035 3.37022 61.8035 3.37022 61.8085 3.37022C61.9143 3.37022 62 3.45585 62 3.56162C62 3.5717 62 3.58177 62 3.58681L60.5795 12.5777C60.5544 12.7036 60.4435 12.7943 60.3126 12.7943Z"
        fill="#009EE3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.86593 0H7.10198C8.29574 0 9.71111 0.0402954 10.653 0.871388C11.2826 1.43049 11.6151 2.31699 11.5395 3.274C11.2776 6.50771 9.34845 8.31597 6.75444 8.31597H4.66411C4.30649 8.31597 4.07479 8.5527 3.97405 9.18735L3.38977 12.8996C3.34947 13.1413 3.24874 13.2824 3.06237 13.2975H0.453242C0.166137 13.2975 0.0603614 13.0809 0.135915 12.5974L2.01469 0.70517C2.09024 0.226662 2.35216 0 2.86593 0Z"
        fill="#113984"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.03418 8.81416L4.77461 4.13486C4.84009 3.72687 5.06171 3.52539 5.51 3.52539H9.74102C10.4412 3.52539 11.0053 3.6362 11.4485 3.83768C11.0254 6.71377 9.16177 8.31551 6.7239 8.31551H4.64365C4.36158 8.31551 4.1601 8.45654 4.03418 8.81416Z"
        fill="#172C70"
      />
    </svg>
  );
};
