import {
  Box,
  Grid,
  InputAdornment,
  styled as MUIStyled,
  Stack,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from "@mui/material";
import * as Icon from "akar-icons";
import { ArrowRepeat, ShippingBoxV2 } from "akar-icons";
import { truncate } from "lodash";
import { rgba } from "polished";
import React, { useState } from "react";
import styled from "styled-components";

import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { GridWrapper, NextOrderPriceLines } from "./constants";
import { OABadge } from "./OABadge";
import { OAButton } from "./OAButton";
import { OACard } from "./OACard";
import {
  OverflowGrid,
  StyledBox,
  StyledContainer,
  StyledImageContainer,
  StyledImageWrapper,
  VerticalProductWrapper,
} from "./OAStyles";
import { StyledTextBox } from "./SearchInput";
import { PaymentCardTitle } from "./Settings";

interface Props {
  mobilePreview: boolean;
  productImage: string | undefined;
  productName: string | undefined;
}

const Caption = MUIStyled("p")`
  color: #202223;

  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0px;

  line-height: 16px;
`;

const H2 = MUIStyled("h2")`
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 15px;
`;

export const WIDTH_SIZES = {
  small: 44,
  medium: 74,
  large: 160,
  largest: 400,
};
const Header = MUIStyled("div")`
  color: #202223;
  font-size: 26px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 32px;
  margin-bottom: 15px;
  margin-top: 0px;
`;

const Body = MUIStyled("p")`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 20px;
  margin: 0px;
`;

const Caption2 = MUIStyled("p")`
  color: #202223;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0px;

  line-height: 24px;
  margin: 0px;
`;

const StyledTabWrapper = MUIStyled(Tabs)`
font-size: 12px;
margin-left: 10px;
  .MuiTabs-fixed {
    align-items: end;
    display: flex;
  }
  [class*="tab-button"] {
    background-color: ${props => rgba(props.theme.modernPrimaryButtonColor!, 0.3)};
    color: ${props => props.theme.modernPrimaryButtonFontColor};

    :first-child {
      margin-right: 10px !important;
    }
    svg {
      stroke: ${props => props.theme.modernPrimaryButtonFontColor};
    }
  }

  [class*="selected"] {
    background-color: ${props => props.theme.modernPrimaryButtonColor} !important;
    color: ${props => props.theme.modernPrimaryButtonFontColor} !important;
    svg {
      stroke: ${props => props.theme.modernPrimaryButtonFontColor} !important;
    }
  }
  & [class*="MuiTabs-indicator"] {
    background-color: ${props => props.theme.modernLineDividers};
    height: 1px;
  }
`;

const StyledTab = MUIStyled(Tab)`
  border-radius: ${props => props.theme.modernButtonBorderRadius}px ${props => props.theme.modernButtonBorderRadius}px 0 0;
  color: ${props => rgba(props.theme.modernCaptionFontColor!, 0.6)};
  font-size: ${props => props.theme.modernCaptionFontSize}px;
  font: inherit;
  font-weight: light;
  max-height: 46px;
  min-height: 46px;
  &[class*="selected"] {
    color: ${props => props.theme.modernCaptionFontColor};
    font-weight: 500;
    svg {
      stroke: ${props => props.theme.modernIconsColor};
    }
  }
  padding: 15px !important;
  text-transform: uppercase;
  transition: 0.3s ease-in-out;
  svg {
    height: 16px;
    stroke: ${props => rgba(props.theme.modernIconsColor!, 0.6)};
    width: 16px;
  }
`;

interface IStyledImgProps {
  borderRadius: number;
}

const SubscriptionsImg = styled.img<IStyledImgProps>`
  border-radius: ${props => props.borderRadius}px;
  width: 100%;
`;

export const OASubscription = ({ mobilePreview, productImage, productName }: Props): JSX.Element => {
  const theme = useTheme();
  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);

  const showSectionTitles = theme.modernThemeSettings?.showHide
    ? theme.modernThemeSettings?.showHide.showSectionTitles
    : true;
  const showUpcomingOrders = theme.modernThemeSettings?.showHide
    ? theme.modernThemeSettings?.showHide.showUpcomingOrders
    : true;

  const [activeTab, setActiveTab] = useState<number>(0);

  const tabChangeHandler = (event: React.SyntheticEvent, index: number) => {
    setActiveTab(index);
  };

  const date = new Date().toLocaleDateString("en-us", {
    weekday: "long",
    month: "short",
    day: "numeric",
  });

  SubscriptionsImg.defaultProps = {
    src: productImage,
  };

  function a11yProps(index: number) {
    return {
      id: `subscription-tab-${index}`,
      "aria-controls": `subscription-tabpanel-${index}`,
    };
  }

  return (
    <React.Fragment>
      <div>
        <span className="anchor" id="subscriptions" />
        {showSectionTitles ? <H2>Subscriptions</H2> : null}
        <StyledTabWrapper value={activeTab} onChange={tabChangeHandler}>
          <StyledTab
            theme={theme}
            label={"Next Order"}
            icon={<ShippingBoxV2 />}
            iconPosition="start"
            {...a11yProps(0)}
            className={"tab-button-0"}
          />
          <StyledTab
            theme={theme}
            label={"Subscriptions"}
            icon={<ArrowRepeat />}
            iconPosition="start"
            {...a11yProps(1)}
            className={"tab-button-1"}
          />
        </StyledTabWrapper>
        <div aria-labelledby="subscription-tabpanel-0" hidden={activeTab !== 0}>
          <Grid container spacing={"20px"} className="subscription-grid">
            {" "}
            <Grid item xs={6} md={mobilePreview ? 12 : 6}>
              {" "}
              <OACard sx={{ height: "340px" }}>
                <PaymentCardTitle>Your next order</PaymentCardTitle>
                <Header>{date}</Header>

                <React.Fragment>
                  <GridWrapper>
                    <Grid
                      container
                      spacing={"10px"}
                      sx={{ alignItems: "center", width: "max-content", position: "relative" }}
                    >
                      <Grid sx={{ width: "max-content", paddingLeft: "10px" }}>
                        <StyledImageWrapper size={"medium"}>
                          <Box sx={{ position: "relative" }}>
                            <StyledImageContainer size={"medium"}>
                              <SubscriptionsImg
                                borderRadius={theme.modernImageBorderRadius || 0}
                                style={{
                                  border:
                                    purchasables.length === 0 ? `1px solid ${theme.modernLineDividers}` : "none",
                                }}
                              />
                            </StyledImageContainer>
                          </Box>
                        </StyledImageWrapper>
                      </Grid>
                    </Grid>
                  </GridWrapper>

                  <NextOrderPriceLines
                    container
                    justifyContent="space-between"
                    alignItems={"center"}
                    direction="row"
                    sx={{
                      margin: "7px 0!important",
                      alignItems: "baseline",
                    }}
                  >
                    <p>{truncate(productName)}</p>
                    <p>$ 0.00</p>
                  </NextOrderPriceLines>
                </React.Fragment>

                <OAButton filled>Manage Next Order </OAButton>
                {showUpcomingOrders ? (
                  <Box
                    sx={{
                      textAlign: "center",
                      marginTop: "15px",
                    }}
                  >
                    <Typography
                      variant="caption1"
                      sx={{
                        fontWeight: 400,
                        fontSize: "12px",
                        lineHeight: "16px",
                        "&:hover": {
                          cursor: "pointer",
                          textDecoration: "underline",
                        },
                      }}
                    >
                      See all upcoming orders
                    </Typography>
                  </Box>
                ) : null}
              </OACard>
            </Grid>
            <Grid item xs={6} md={mobilePreview ? 12 : 6}>
              <OACard
                sx={{
                  height: "340px",
                  padding: "20px",
                }}
              >
                <StyledBox>
                  <PaymentCardTitle style={{ display: "block", whiteSpace: "nowrap" }}>
                    Add to your next order
                  </PaymentCardTitle>
                  <StyledTextBox
                    isMobile={!!mobilePreview}
                    searchExpanded={!mobilePreview}
                    type="search"
                    placeholder="Search"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon.Search size={12} color="#4B4B4B" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </StyledBox>

                <React.Fragment>
                  <Box
                    sx={{
                      overflowX: "scroll",
                      height: "max-content",
                      overflowY: "hidden",
                      marginTop: "15px",
                      marginRight: "-20px",
                    }}
                  >
                    <OverflowGrid container>
                      <VerticalProductWrapper item>
                        <StyledImageWrapper size={"medium"}>
                          <Box sx={{ position: "relative" }}>
                            <StyledImageContainer size={"medium"}>
                              <SubscriptionsImg
                                borderRadius={theme.modernImageBorderRadius || 0}
                                style={{ height: 74 }}
                              />
                            </StyledImageContainer>
                          </Box>
                        </StyledImageWrapper>
                        <OAButton>Add</OAButton>

                        <React.Fragment>
                          <Body style={{ fontSize: "12px", lineHeight: "20px" }}>{truncate(productName)}</Body>
                          <Stack direction="row" spacing="3px" sx={{ fontSize: "12px" }}>
                            <span>$0.00</span>
                          </Stack>
                        </React.Fragment>
                      </VerticalProductWrapper>
                    </OverflowGrid>
                  </Box>
                </React.Fragment>
              </OACard>
            </Grid>
          </Grid>
        </div>

        <div aria-labelledby="subscription-tabpanel-1" hidden={activeTab !== 1}>
          <Grid container spacing={"20px"} className="subscription-grid">
            <Grid item xs={6} md={mobilePreview ? 12 : 6}>
              <OACard sx={{ height: "340px" }}>
                <PaymentCardTitle>Subscription #1</PaymentCardTitle>
                <Header>Every 7 days</Header>
                <p>145 W 28th St, New York, New York, 10001</p>
                <StyledContainer sx={{ marginTop: "7px" }}>
                  <OABadge status={"ACTIVE"} sx={{ marginBottom: "7px", padding: "2px 10px" }} />

                  <GridWrapper>
                    <Grid
                      container
                      spacing={"10px"}
                      sx={{ alignItems: "center", width: "max-content", position: "relative" }}
                    >
                      <Grid sx={{ width: "max-content", paddingTop: "10px" }}>
                        <StyledImageWrapper size={"small"}>
                          <Box sx={{ position: "relative" }}>
                            <StyledImageContainer size={"small"}>
                              <SubscriptionsImg borderRadius={theme.modernSmallImageBorderRadius || 0} />
                            </StyledImageContainer>
                          </Box>
                        </StyledImageWrapper>
                      </Grid>
                    </Grid>
                  </GridWrapper>

                  <NextOrderPriceLines
                    container
                    justifyContent="space-between"
                    alignItems={"center"}
                    direction="row"
                    sx={{
                      margin: "7px 0!important",
                    }}
                  >
                    <Caption>{truncate(productName)}</Caption>
                    <Caption2>$0.00</Caption2>
                  </NextOrderPriceLines>
                </StyledContainer>

                <OAButton filled>Manage subscription</OAButton>
              </OACard>
            </Grid>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};
