import { Text } from "@shopify/polaris";
import React, { useState } from "react";
import styled from "styled-components";

import { AccordionOpenIcon } from "@vendor-app/app/_sharedComponents/Icons";

const AccordionContainer = styled.div`
  border-bottom: 1px solid #e4e5e7;
  padding: 1rem;
`;

const AccordionIconContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
`;

const OpenArrow = styled.span`
  align-items: center;

  svg {
    transform: rotate(90deg);
  }
`;

interface IAccordionProps {
  children: any;
  title: string;
}

export const Accordion = ({ children, title }: IAccordionProps) => {
  const [isActive, setIsActive] = useState<Boolean>(true);

  return (
    <AccordionContainer className="container">
      <React.Fragment>
        <AccordionIconContainer onClick={() => setIsActive(prevState => !prevState)}>
          <Text as="h4" variant="headingSm">
            {title}
          </Text>
          <span>
            {isActive ? (
              <span>
                <AccordionOpenIcon />
              </span>
            ) : (
              <OpenArrow>
                <AccordionOpenIcon />
              </OpenArrow>
            )}
          </span>
        </AccordionIconContainer>
        {!!isActive && <div>{children}</div>}
      </React.Fragment>
    </AccordionContainer>
  );
};
