import { css } from "@codemirror/lang-css";
import { Container } from "@mui/material";
import MUIBox from "@mui/material/Box";
import MUIModal from "@mui/material/Modal";
import MUIStack from "@mui/material/Stack";
import { ThemeProvider as MUIThemeProvider, createTheme } from "@mui/material/styles";
import {
  Button,
  ContextualSaveBar,
  Icon,
  LegacyCard,
  LegacyStack,
  Link,
  Spinner,
  Tabs,
  Text,
} from "@shopify/polaris";
import {
  CircleInformationMajor,
  DesktopMajor,
  ExternalSmallMinor,
  HintMajor,
  MobileMajor,
} from "@shopify/polaris-icons";
import {
  ICustomerPortalTheme,
  ICustomerPortalThemeForm,
  SECTION,
  customerPortalValidationSchema,
} from "@smartrr/shared/entities/CustomerPortalTheme";
import { IInstaAllPostData } from "@smartrr/shared/entities/Instagram";
import { IPurchasable } from "@smartrr/shared/entities/Purchasable";
import { ISmartrrShopTheme } from "@smartrr/shared/shopifyRest/theme";
import { defaultCustomerPortalThemeConfig } from "@smartrr/shared/themes/defaultThemeConfig";
import { trimObjectStringProperties } from "@smartrr/shared/utils/trimObjectStringProperties";
import { isEqual, isNil, isUndefined, omit, pickBy, uniq } from "lodash";
import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { useTypedForm } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";
import {
  loadCustomerPortalTheme,
  updateCustomerPortalTheme,
} from "@vendor-app/app/_state/actionCreators/customerPortalTheme";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import placeHolderImage from "./placeholderImage.png";
import {
  BannerText,
  ButtonWrapper,
  Content,
  IconTab,
  IconWrapper,
  InnerWrapper,
  LeftTab,
  LinkWrapper,
  MarketingBanner,
  ModernOverrideCard,
  ModernThemeContainer,
  PreviewText,
  RightTab,
  SideBar,
  Wrapper,
} from "./styles";
import CodeMirror from "@uiw/react-codemirror";
import { ApplyToThemeModal } from "../../components/ApplyToThemeModal";
import { AquariusHeader } from "../../components/PreviewComponents/AquariusHeader";
import { Instagram } from "../../components/PreviewComponents/Instagram";
import { OALoyalty } from "../../components/PreviewComponents/OALoyalty";
import { OAOrderHistory } from "../../components/PreviewComponents/OAOrderHistory";
import { OASubscription } from "../../components/PreviewComponents/OASubscription";
import { OASettings } from "../../components/PreviewComponents/Settings";
import { Trending } from "../../components/PreviewComponents/Trending";
import ThemePageSidebar from "../SideBar/GlobalAndPortalTab";
import ProductSidebar from "../SideBar/ProductAndAdvancedTab";
import { useThemeSidebarSections } from "./hooks";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mdsm: true; // adds the `mobile` breakpoint
  }

  interface Theme extends ICustomerPortalTheme {
    breakPoints?: BreakpointOverrides;
  }

  interface TypographyVariants {
    caption1: React.CSSProperties;
    caption2: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    caption1?: React.CSSProperties;
    caption2?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    //Not supporting default `caption` variant, replacing it with caption1 & caption2 for more flexibility
    caption1: true;
    caption2: true;
    // Unsupported ---
    subtitle1: false;
    subtitle2: false;
    caption: false;
    overline: false;
  }
}

export interface IGlobalThemesProps {
  customerPortalTheme: ICustomerPortalTheme;
  themes: ISmartrrShopTheme[];
}

enum ThemeSettingsTabs {
  GLOBAL = "global",
  PRODUCT = "product",
}

const TabOptions = [
  {
    id: ThemeSettingsTabs.GLOBAL,
    content: "Portal Settings",
  },
  {
    id: ThemeSettingsTabs.PRODUCT,
    content: "Advanced",
  },
];

export function Theming({ themes, customerPortalTheme }: IGlobalThemesProps): JSX.Element {
  const { addToast } = useToast();
  const dispatch = useSmartrrVendorDispatch();
  const [overrideCSSInput, setOverrideCSSInput] = useState<string>();
  const [modernOverrideCSSInput, setModernOverrideCSSInput] = useState<string>();
  const [postData, setPostData] = useState<IInstaAllPostData | undefined>();
  const [selectedPreview, setSelectedPreview] = useState<string>("mobile");
  const [selectedTab, setSelectedTab] = useState(ThemeSettingsTabs.GLOBAL);
  const [showThemesModal, setShowThemesModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disableDiscard, setDisableDiscard] = useState(false);

  const { purchasables } = useSmartrrVendorSelector(state => state.purchasables);

  const customerPortalThemeIsLoading = useSmartrrVendorSelector(state => state.customerPortalTheme.isLoading);
  const customerPortalThemeValues = useSmartrrVendorSelector(
    state => state.customerPortalTheme.customerPortalTheme
  );

  const handleOpen = () => setIsModalOpen(true);
  const handleClose = () => setIsModalOpen(false);

  const onSave = async () => {
    setShowThemesModal(true);
    setDisableDiscard(false);
  };
  const onClose = useCallback(() => setShowThemesModal(false), []);

  const onApplyCSSToTheme = async (theme: ISmartrrShopTheme) => {
    const trimmedValues = trimObjectStringProperties(themeValues);

    await dispatch(
      updateCustomerPortalTheme({
        updatedThemeProperties: {
          ...trimmedValues,
          overrideCSS: overrideCSSInput || "",
          modernOverrideCSS: modernOverrideCSSInput || "",
        },
        themeId: theme.id,
        addToast,
      })
    );
    setShowThemesModal(false);
    await dispatch(loadCustomerPortalTheme());
  };

  // TODO: Investigate how we can break all uses of useTypedForm into a separate hook to be called by children (maybe useContext?) to prevent prop drilling
  const {
    useField: useThemeField,
    useValues: useThemeValues,
    setFieldValue: setThemeFieldValue,
  } = useTypedForm<ICustomerPortalThemeForm>({
    initialValues: {
      ...defaultCustomerPortalThemeConfig,
      ...pickBy(customerPortalTheme, v => !isNil(v)), // gets rid of null keys
    },
    validationSchema: customerPortalValidationSchema,
    validateOnChange: true,
    onSubmit: onSave,
  });

  const themeValues = useThemeValues();

  const { updatedSectionOrder, updatedSectionValues } = useThemeSidebarSections(useThemeValues);

  useEffect(() => {
    if (
      (themeValues.modernThemeSettings.sectionsOrder &&
        !isEqual(themeValues.modernThemeSettings.sections, updatedSectionValues)) ||
      !isEqual(themeValues.modernThemeSettings.sectionsOrder, updatedSectionOrder)
    ) {
      setDisableDiscard(true);
      setThemeFieldValue("modernThemeSettings", {
        ...themeValues.modernThemeSettings,
        sections: updatedSectionValues,
        sectionsOrder: updatedSectionOrder,
      });
    }

    if (isUndefined(themeValues.modernThemeSettings.subscriptionActions.subscriptionSwap)) {
      setThemeFieldValue("modernThemeSettings.subscriptionActions", {
        ...themeValues.modernThemeSettings.subscriptionActions,
        swap: defaultCustomerPortalThemeConfig.modernThemeSettings.subscriptionActions.subscriptionSwap,
      });
      setDisableDiscard(true);
    }

    // check to see if showHide is defined, if not initialize it
    if (isUndefined(themeValues.modernThemeSettings.showHide)) {
      setThemeFieldValue("modernThemeSettings", {
        ...themeValues.modernThemeSettings,
        showHide: defaultCustomerPortalThemeConfig.modernThemeSettings.showHide,
      });
      setDisableDiscard(true);
    } else {
      // if showHide is defined, check to see if any keys are missing
      const showHideKeys = Object.keys(themeValues.modernThemeSettings.showHide);
      const defaultShowHideKeys = Object.keys(defaultCustomerPortalThemeConfig.modernThemeSettings.showHide);
      const missingKeys = defaultShowHideKeys.filter(key => !showHideKeys.includes(key));
      for (const key of missingKeys) {
        setThemeFieldValue("modernThemeSettings.showHide", {
          ...themeValues.modernThemeSettings.showHide,
          // @ts-ignore - key is a string
          [key]: defaultCustomerPortalThemeConfig.modernThemeSettings.showHide[key],
        });
        setDisableDiscard(true);
      }
    }
  }, []);

  const codeMirrorContainerStyle: CSSProperties = {
    height: 550,
    width: "inherit",
    maxWidth: 490,
    overflowY: "auto",
    borderRadius: "8px",
    boxShadow: "0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25)",
  };

  useEffect(() => {
    setOverrideCSSInput(customerPortalTheme?.overrideCSS === undefined ? "" : customerPortalTheme?.overrideCSS);
    setModernOverrideCSSInput(
      customerPortalTheme?.modernOverrideCSS === undefined ? "" : customerPortalTheme?.modernOverrideCSS
    );
  }, [customerPortalTheme]);

  const hasThemeChanges = useMemo(() => {
    const filteredSections = uniq(customerPortalThemeValues.modernThemeSettings?.sectionsOrder);
    const cleanedCustomerPortalThemeValues = omit(
      {
        ...customerPortalThemeValues,
        modernThemeSettings: {
          ...customerPortalThemeValues.modernThemeSettings,
          sectionsOrder: filteredSections,
        },
      },
      "deletedAt"
    );

    return (
      !isEqual(cleanedCustomerPortalThemeValues, themeValues) ||
      !isEqual(
        modernOverrideCSSInput,
        customerPortalTheme?.modernOverrideCSS === undefined ? "" : customerPortalTheme?.modernOverrideCSS
      )
    );
  }, [themeValues, customerPortalThemeValues, modernOverrideCSSInput, customerPortalTheme?.modernOverrideCSS]);

  const onThemingDiscard = () => {
    setModernOverrideCSSInput(customerPortalTheme?.modernOverrideCSS || "");
    for (const key of Object.keys(omit(customerPortalThemeValues, "deletedAt"))) {
      setThemeFieldValue(key, customerPortalThemeValues[key]);
    }
  };

  const mobilePreviewStyle = {
    position: "absolute" as const,
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "375px",
    height: "90vh",
    bgcolor: "#F6F6F7",
    border: "1px solid #C9CCCF",
    borderRadius: "8px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
    boxShadow: 24,
    overflow: "scroll",
  };

  const desktopPreviewStyle = {
    position: "absolute" as const,
    top: "52%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100vw",
    height: "95vh",
    bgcolor: "#F6F6F7",
    boxShadow: 24,
    overflow: "scroll",
  };

  const cpTheme = createTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          mdsm: 700,
        },
      },
      typography: {
        // Only supporting variants outlined in designs
        subtitle1: undefined,
        subtitle2: undefined,
        caption: undefined,
        overline: undefined,
      },
    },
    themeValues
  );

  const getProductImageWithName = () => {
    if (purchasables.length >= 1) {
      const [firstPurchasableImageWithName] = purchasables.map((purchasable: IPurchasable) => {
        const image = purchasable.purchasableImages?.[0] || placeHolderImage;
        const name = purchasable.purchasableName;
        const variantName = purchasable?.vnts?.[0]?.purchasableVariantName || "Variant Name";
        return [image, name, variantName];
      });
      return firstPurchasableImageWithName;
    }
    return [placeHolderImage, "product name", "varaint name"];
  };

  const [fpImage, fpName, fpVariantName] = getProductImageWithName();

  const Aquarius = useCallback(() => {
    return (
      <MUIThemeProvider theme={cpTheme}>
        <AquariusHeader mobilePreview={selectedPreview === "mobile"} themeValues={themeValues} />
        <InnerWrapper background={themeValues.modernPageBackground}>
          <Container>
            {!!themeValues.modernThemeSettings.sections.banner.display &&
              !!themeValues.modernThemeSettings?.sections.banner.text && (
                <MUIBox pt="20px">
                  <MarketingBanner>
                    <BannerText
                      dangerouslySetInnerHTML={{ __html: themeValues.modernThemeSettings.sections.banner.text }}
                    />
                  </MarketingBanner>
                </MUIBox>
              )}

            <MUIStack spacing="20px">
              {[
                <React.Fragment
                  key={
                    !!themeValues.modernThemeSettings.sectionsOrder &&
                    themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.SUBSCRIPTION)
                  }
                >
                  {!!themeValues.modernThemeSettings.sections.subscriptions.display && (
                    <OASubscription
                      productName={fpName}
                      productImage={fpImage}
                      mobilePreview={selectedPreview === "mobile"}
                    />
                  )}
                </React.Fragment>,
                <React.Fragment
                  key={
                    !!themeValues.modernThemeSettings.sectionsOrder &&
                    themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.ORDERHISTORY)
                  }
                >
                  {!!themeValues.modernThemeSettings.sections.orderHistory.display && (
                    <OAOrderHistory
                      productImage={fpImage}
                      productName={fpName}
                      mobilePreview={selectedPreview === "mobile"}
                    />
                  )}
                </React.Fragment>,
                <React.Fragment
                  key={
                    !!themeValues.modernThemeSettings.sectionsOrder &&
                    themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.SETTINGS)
                  }
                >
                  {!!themeValues.modernThemeSettings.sections.settings.display && (
                    <OASettings mobilePreview={selectedPreview === "mobile"} />
                  )}
                </React.Fragment>,
                <React.Fragment
                  key={
                    !!themeValues.modernThemeSettings.sectionsOrder &&
                    themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.INSTAGRAM)
                  }
                >
                  {themeValues.modernThemeSettings.sections.instagram?.display ? (
                    <Instagram postData={postData} setPostData={setPostData} />
                  ) : null}
                </React.Fragment>,
                <React.Fragment
                  key={
                    !!themeValues.modernThemeSettings.sectionsOrder &&
                    themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.LOYALTY)
                  }
                >
                  {!!themeValues.modernThemeSettings.sections.rewards &&
                    !!themeValues.modernThemeSettings.sections.rewards.display && (
                      <OALoyalty
                        productName={fpName}
                        productImage={fpImage}
                        variantName={fpVariantName}
                        mobilePreview={selectedPreview === "mobile"}
                      />
                    )}
                </React.Fragment>,
                <React.Fragment
                  key={
                    !!themeValues.modernThemeSettings.sectionsOrder &&
                    themeValues.modernThemeSettings.sectionsOrder.indexOf(SECTION.TRENDING)
                  }
                >
                  {!!themeValues.modernThemeSettings.sections.trending &&
                    !!themeValues.modernThemeSettings.sections.trending.display && (
                      <Trending
                        mobilePreview={selectedPreview === "mobile"}
                        previewProduct={{ title: fpName, img: fpImage }}
                      />
                    )}
                </React.Fragment>,
              ]
                .map(section => section)
                .filter(section => Number(section.key) >= 0) // catching any sections that have been removed from sectionOrder but are still rendering their preview sections
                .sort((a, b) => Number(a.key) - (b && Number(b.key)))}
            </MUIStack>
          </Container>
        </InnerWrapper>
      </MUIThemeProvider>
    );
  }, [themeValues, selectedPreview, postData]);

  if (!themeValues.modernThemeSettings.sectionsOrder) {
    return <Spinner />;
  }

  return (
    <Wrapper>
      <SideBar>
        <Tabs
          fitted
          tabs={TabOptions}
          selected={selectedTab === ThemeSettingsTabs.GLOBAL ? 0 : 1}
          onSelect={index => setSelectedTab(index === 0 ? ThemeSettingsTabs.GLOBAL : ThemeSettingsTabs.PRODUCT)}
        >
          {selectedTab === ThemeSettingsTabs.GLOBAL ? (
            <ThemePageSidebar
              themeValues={themeValues}
              setThemeFieldValue={setThemeFieldValue}
              useThemeField={useThemeField}
            />
          ) : (
            <ProductSidebar
              customerPortalTheme={customerPortalTheme}
              themeValues={themeValues}
              setThemeFieldValue={setThemeFieldValue}
              useThemeField={useThemeField}
            />
          )}
        </Tabs>
      </SideBar>
      <Content>
        {!!hasThemeChanges && (
          <ContextualSaveBar
            message="Unsaved changes"
            saveAction={{
              onAction: onSave,
              loading: customerPortalThemeIsLoading,
              content: "Save changes",
              disabled: false,
            }}
            discardAction={{
              content: "Discard changes",
              onAction: onThemingDiscard,
              disabled: disableDiscard,
            }}
          />
        )}
        <ApplyToThemeModal
          open={showThemesModal}
          themes={themes}
          onClose={onClose}
          onSubmit={onApplyCSSToTheme}
          lastSavedThemeId={themeValues.lastSavedThemeId}
        />
        <React.Fragment>
          <div style={{ display: " flex", justifyContent: "center" }}>
            <IconTab style={{ marginRight: "10px" }}>
              <LeftTab
                preview={selectedPreview === "mobile"}
                onClick={() => {
                  setSelectedPreview("mobile");
                  handleOpen();
                }}
              >
                <Icon source={MobileMajor} color="base" />
              </LeftTab>
              <RightTab
                preview={selectedPreview === "desktop"}
                onClick={() => {
                  setSelectedPreview("desktop");
                  handleOpen();
                }}
              >
                <Icon source={DesktopMajor} color="base" />
              </RightTab>
            </IconTab>
            <PreviewText>{` You're previewing how your account portal will look to customers`}</PreviewText>
          </div>

          <ModernThemeContainer>
            <div style={{ position: "relative" }}>
              <MUIBox
                sx={{
                  height: 960,
                  width: 375,
                  marginRight: "1.5rem",
                  border: "1px solid lightgray",
                  borderRadius: "5px",
                  overflow: "scroll",
                }}
              >
                <Aquarius />
                <div>
                  <MUIModal
                    open={isModalOpen}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    BackdropProps={{
                      style: { backgroundColor: "rgba(0, 0, 0, 0.8)" },
                    }}
                  >
                    <React.Fragment>
                      <div className="Polaris-Frame__ContextualSaveBar Polaris-Frame-CSSAnimation--startFade Polaris-Frame-CSSAnimation--endFade">
                        {/* eslint-disable-next-line react/no-unknown-property */}
                        <div p-color-scheme="dark">
                          <div
                            className="Polaris-Frame-ContextualSaveBar"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "0 32px",
                            }}
                          >
                            <IconTab>
                              <LeftTab
                                preview={selectedPreview === "mobile"}
                                onClick={() => setSelectedPreview("mobile")}
                              >
                                <Icon source={MobileMajor} color="base" />
                              </LeftTab>
                              <RightTab
                                preview={selectedPreview === "desktop"}
                                onClick={() => setSelectedPreview("desktop")}
                              >
                                <Icon source={DesktopMajor} color="base" />
                              </RightTab>
                            </IconTab>
                            <ButtonWrapper>
                              <Button
                                primary
                                onClick={() => {
                                  setSelectedPreview("mobile");
                                  handleClose();
                                }}
                              >
                                Exit preview
                              </Button>
                            </ButtonWrapper>
                          </div>
                        </div>
                      </div>

                      <MUIBox sx={selectedPreview === "desktop" ? desktopPreviewStyle : mobilePreviewStyle}>
                        <div style={{ position: "relative" }}>
                          <Aquarius />
                        </div>
                      </MUIBox>
                    </React.Fragment>
                  </MUIModal>
                </div>
              </MUIBox>
            </div>
            <div>
              <ModernOverrideCard>
                <LegacyCard
                  sectioned
                  title={
                    <LegacyStack>
                      <Icon source={HintMajor} color="base" />
                      <Text variant="headingMd" as="h2">
                        Override Tips
                      </Text>
                    </LegacyStack>
                  }
                >
                  <div>
                    <div>
                      Custom code placed below will override any global or product settings. Use CSS rules to
                      apply branded customization or to add custom font and typography styling.
                    </div>

                    <div style={{ marginTop: "10px" }}>
                      CSS override rules will be scoped to the following selectors:
                    </div>
                    <br />
                    <div>#smartrr_account</div>
                    <div>#smartrr_wrapper</div>
                    <div>#smartrr_toast_container</div>
                    <div>#smartrr_inner</div>
                    <div>#smartrr_header</div>

                    <IconWrapper>
                      <Icon source={CircleInformationMajor} color="primary" />
                      View Our{" "}
                      <Link
                        external
                        url={`https://help.smartrr.com/docs/support/admin-portal/what-are-css-overrides`}
                      >
                        <LinkWrapper>
                          CSS Variables.{<Icon source={ExternalSmallMinor} color="interactive" />}
                        </LinkWrapper>
                      </Link>
                    </IconWrapper>
                  </div>
                </LegacyCard>
              </ModernOverrideCard>
              <LegacyCard sectioned title="CSS Overrides">
                <div style={codeMirrorContainerStyle}>
                  <CodeMirror
                    value={modernOverrideCSSInput ?? ""}
                    onChange={setModernOverrideCSSInput}
                    extensions={[css()]}
                  />
                </div>
              </LegacyCard>
            </div>
          </ModernThemeContainer>
        </React.Fragment>
      </Content>
    </Wrapper>
  );
}
