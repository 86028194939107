import { Box, Grid, styled as muiStyled } from "@mui/material";
import styled from "styled-components";

interface Props {
  size?: keyof typeof WIDTH_SIZES;
}

export const WIDTH_SIZES = {
  small: 44,
  medium: 74,
  large: 160,
  largest: 400,
};

export const StyledContainer = muiStyled(Box)`
  border: ${props => props.theme.modernSmallCardBorderWidth}px solid
    ${props => props.theme.modernSmallCardBorderColor};
  border-radius: ${props => props.theme.modernSmallCardBorderRadius}px !important;
  height: 125px;
  overflow: hidden;
  padding: 15px 20px;
  width: 100%;
`;

export const StyledImageWrapper = styled.div<{
  size: Props["size"];
}>`
  border-radius: ${props => {
    switch (props.size) {
      case "small": {
        return props.theme.smallProductImage?.borderRadius;
      }
      case "medium": {
        return props.theme.mediumProductImage?.borderRadius;
      }
      case "large": {
        return props.theme.largeProductImage?.borderRadius;
      }
      case "largest": {
        return props.theme.largestProductImage?.borderRadius;
      }
      default: {
        return 0;
      }
    }
  }};
`;

export const VerticalProductWrapper = styled(Grid)`
  padding: 10px;
  transition: 0.3s ease-in-out;
  width: 110px;

  & > * {
    max-width: 90px;
  }
  & p {
    margin-bottom: 3px;
  }

  & button {
    margin: 7px 0;
    height: 30px;
  }

  & img,
  & .smartrr-portal-product-image-container,
  & .smartrr-portal-product-image-wrapper {
    height: 90px;
    width: 90px;
  }
  &[class*="disabled"] {
    pointer-events: none;
    &:hover {
      background-color: inherit;
    }
  }

  &[class*="list-item"],
  &[class*="list-item-selected"],
  &[class*="addon-selected"] {
    border-radius: 8px;
  }
  &[class*="list-item"]:hover,
  &[class*="list-item-selected"]:hover {
    background-color: rgba(244, 244, 244, 0.5);
  }

  &[class*="addon-selected"],
  &[class*="list-item-selected"] {
    background-color: #f4f4f4;
  }
`;

export const OverflowGrid = styled(Grid)`
  direction: ltr;
  height: max-content;
  max-height: 100%;
  width: max-content;
  will-change: transform;
`;

export const StyledImageContainer = styled.div<{
  size: Props["size"];
}>`
  align-items: center;
  border-radius: ${props => {
    switch (props.size) {
      case "small": {
        return props.theme.smallProductImage?.borderRadius;
      }
      case "medium": {
        return props.theme.mediumProductImage?.borderRadius;
      }
      case "large": {
        return props.theme.largeProductImage?.borderRadius;
      }
      default: {
        return 0;
      }
    }
  }};
  display: flex;
  height: ${props => WIDTH_SIZES[props.size || "medium"]}px;
  justify-content: center;
  min-height: ${props => WIDTH_SIZES[props.size || "medium"]}px;
  min-width: ${props => WIDTH_SIZES[props.size || "medium"]}px;
  overflow: hidden;
  position: relative;
  &.error {
    position: relative;
    &:after {
      background: ${props => props.theme.body?.backgroundColor || "white"};

      border: 1px solid ${props => props.theme.accentText?.color};
      content: "";
      display: block;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  width: ${props => WIDTH_SIZES[props.size || "medium"]}px;
`;

export type ImageDirection = "vertical" | "horizontal";
export const StyledImage = styled.img.attrs<{
  direction: ImageDirection;
}>(props => ({
  ...props,
}))<{
  size: Props["size"];
  isLoaded?: boolean;
  direction?: ImageDirection;
}>`
  max-height: 100%;
  max-width: 100%;
  transition: opacity 0.15s ease-in-out;
  ${props =>
    props.isLoaded
      ? `
    opacity: 1;
  `
      : `
    visibility: hidden;
    opacity: 0;
  `}
  ${props =>
    props.direction === "horizontal"
      ? `
        min-height: 0;
        min-width: ${WIDTH_SIZES[props.size || "medium"]}px;
      `
      : `
        min-height: ${WIDTH_SIZES[props.size || "medium"]}px;
        min-width: 0;
      `}
`;

export const AddOnSectionWrapper = styled(Box)`
  position: relative;
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: space-between;
`;
