import { Box, Grid, Stack, Typography, styled as muiStyled, useTheme } from "@mui/material";
import { Question } from "akar-icons";
import React from "react";
import styled from "styled-components";

import { OABadge } from "./OABadge";
import { OAButton } from "./OAButton";
import { OACard } from "./OACard";

interface Props {
  productName: string;
  productImage: string;
  variantName: string;
  mobilePreview: boolean;
}

const IncentiveImageContainer = muiStyled(Box)(({ theme, imageColor }: { theme: any; imageColor: string }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "90px",
  height: "90px",
  borderRadius: theme.modernImageBorderRadius,
  backgroundColor: imageColor,

  "& .MuiTypography-root": {
    margin: 0,
  },
}));

interface IStyledImgProps {
  borderRadius: number;
}
const SubscriptionsImg = styled.img<IStyledImgProps>`
  border-radius: ${props => props.borderRadius}px;
`;

export const IncentiveImage = ({ theme, imageColor, name }: { theme: any; imageColor: string; name: string }) => {
  return (
    <IncentiveImageContainer imageColor={imageColor} theme={theme}>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "normal",
          lineHeight: "28px",
          letterSpacing: "0px",
        }}
        variant="h4"
      >
        {name}
      </Typography>
    </IncentiveImageContainer>
  );
};

export const OAProductsList = ({ productName, productImage, variantName, mobilePreview }: Props) => {
  const theme = useTheme();

  SubscriptionsImg.defaultProps = {
    src: productImage,
  };
  return (
    <Grid
      item
      xs={12}
      md={mobilePreview ? 12 : 8}
      sx={{
        transition: ".3s ease-in-out",
      }}
    >
      <OACard sx={{ height: "100%", padding: "20px 0px 5px 20px !important" }}>
        <Stack direction="column" alignItems="center" spacing="25px">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ width: "100%", paddingRight: "20px" }}
          >
            <Stack direction="row" spacing="7px" alignItems="center">
              <Typography
                sx={{ fontSize: "20px", fontWeight: "normal", lineHeight: "28px", letterSpacing: "0px" }}
                variant="h4"
                style={{ marginBottom: "0px" }}
              >
                Point balance
              </Typography>
              <OABadge
                icon="trophy"
                label={"1,000"}
                sx={{
                  padding: "5px",
                  minHeight: "32px",
                  minWidth: "66px",
                  fontSize: "20px !important",
                  svg: {
                    minHeight: "18px",
                    minWidth: "18px",
                  },
                }}
              />
            </Stack>
            <Stack direction="row" alignItems="center" spacing="7px" sx={{ cursor: "pointer" }}>
              <Question strokeWidth={2} size={16} />
              {!mobilePreview && (
                <Typography
                  sx={{ fontSize: "12px", fontWeight: "normal", lineHeight: "16px", letterSpacing: "0px" }}
                  variant="caption1"
                >
                  How do I earn points?{" "}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing="20px"
            sx={{ paddingBottom: "15px", overflowX: "auto", overflowY: "hidden", width: "100%" }}
          >
            <Stack direction="column" spacing="7px" alignItems="center" sx={{ maxWidth: "90px" }}>
              <OABadge
                label="500"
                icon="trophy"
                sx={{
                  padding: "5px",
                }}
              />

              <IncentiveImage theme={theme} imageColor={"#E0FFEB"} name={"10% off"} />

              <OAButton
                sx={{ height: "30px" }}
                onClick={() => {
                  return null;
                }}
              >
                Redeem
              </OAButton>
              <Typography variant="body1" sx={{ marginRight: "auto !important" }}>
                10% off your next order
              </Typography>
            </Stack>

            <Stack direction="column" spacing="7px" alignItems="center" sx={{ maxWidth: "90px" }}>
              <OABadge
                label="750"
                icon="trophy"
                sx={{
                  padding: "5px",
                }}
              />
              <SubscriptionsImg
                borderRadius={theme.modernImageBorderRadius || 0}
                style={{ height: "90px", width: "90px" }}
              />
              <OAButton sx={{ height: "30px" }} onClick={() => null}>
                Redeem
              </OAButton>
              <Stack direction="column" spacing="3px">
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "normal",
                    lineHeight: "20px",
                    letterSpacing: "0px",
                    marginRight: "auto !important",
                  }}
                  variant="body1"
                >
                  {productName}
                </Typography>
                <Typography variant="caption1" sx={{ width: "90px" }}>
                  {variantName}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </OACard>
    </Grid>
  );
};
