import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { ICustomerPortalThemeForm, SECTION } from "@smartrr/shared/entities/CustomerPortalTheme";
import { defaultCustomerPortalThemeConfig } from "@smartrr/shared/themes/defaultThemeConfig";
import { useIsFeatureAvailable } from "@vendor-app/app/AdminRoute/components/authorization/featureAccess";
import { uniq } from "lodash";
import { useMemo } from "react";

export const useThemeSidebarSections = (useThemeValues: () => ICustomerPortalThemeForm) => {
  const themeValues = useThemeValues();
  const sectionsOrder = themeValues.modernThemeSettings.sectionsOrder;
  const defaultSectionValues = defaultCustomerPortalThemeConfig.modernThemeSettings.sections;

  const isLoyaltyAvailable = useIsFeatureAvailable(FeatureEnum.LOYALTY);
  const isTrendingAvailable = useIsFeatureAvailable(FeatureEnum.TRENDING);

  const updatedSectionOrder: SECTION[] = [...sectionsOrder];
  const updatedSectionValues = { ...themeValues.modernThemeSettings.sections };

  const isSectionInTheme = (section: SECTION) => sectionsOrder.includes(section);
  const removeSection = (section: SECTION) => updatedSectionOrder.splice(sectionsOrder.indexOf(section), 1);

  return useMemo(() => {
    if (isTrendingAvailable && !isSectionInTheme(SECTION.TRENDING)) {
      updatedSectionOrder.push(SECTION.TRENDING);
      updatedSectionValues.trending = defaultSectionValues.trending;
    } else if (!isTrendingAvailable && isSectionInTheme(SECTION.TRENDING)) {
      removeSection(SECTION.TRENDING);
    }

    if (isLoyaltyAvailable && !isSectionInTheme(SECTION.LOYALTY)) {
      updatedSectionOrder.push(SECTION.LOYALTY);
      updatedSectionValues.rewards = defaultSectionValues.rewards;
    } else if (!isLoyaltyAvailable && isSectionInTheme(SECTION.LOYALTY)) {
      removeSection(SECTION.LOYALTY);
    }

    if (!isSectionInTheme(SECTION.INSTAGRAM)) {
      updatedSectionOrder.push(SECTION.INSTAGRAM);
      updatedSectionValues.instagram = defaultSectionValues.instagram;
    }
    return { updatedSectionOrder: uniq(updatedSectionOrder), updatedSectionValues };
  }, []);
};
