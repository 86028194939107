import _ from "lodash";
import React from "react";

import { AmericanExpress } from "./logos/AmericanExpress";
import { Discover } from "./logos/Discover";
import { MasterCard } from "./logos/MasterCard";
import { PayPal } from "./logos/PayPal";
import { UnionPay } from "./logos/UnionPay";
import { Visa } from "./logos/Visa";

export type ValidCards =
  | "American Express"
  | "Visa"
  | "Master Card"
  | "Mastercard"
  | "Discover"
  | "PayPal"
  | "Paypal"
  | "Unionpay"
  | undefined;

interface Props {
  cardName: ValidCards | null;
}

export const CardLogo = ({ cardName }: Props): JSX.Element => {
  if (cardName) {
    switch (_.startCase(_.toLower(cardName))) {
      case "American Express": {
        return <AmericanExpress />;
      }
      case "Visa": {
        return <Visa />;
      }
      case "Master Card":
      case "Mastercard": {
        return <MasterCard />;
      }
      case "Unionpay": {
        return <UnionPay />;
      }
      case "Discover": {
        return <Discover />;
      }
      case "PayPal":
      case "Paypal": {
        return <PayPal />;
      }
      default: {
        // Shouldn't hit here
        return <React.Fragment />;
      }
    }
  }
  return <React.Fragment />;
};
