import { Box, Grid, useTheme } from "@mui/material";
import React from "react";

import { OAProductsList } from "./OAProductsList";
import { OAReferralCard } from "./OAReferralCard";
import { SectionTitle } from "./Settings";

interface Props {
  productName: string;
  productImage: string;
  variantName: string;
  mobilePreview: boolean;
}
export const OALoyalty = ({ productName, productImage, variantName, mobilePreview }: Props) => {
  const theme = useTheme();
  const showSectionTitles = theme.modernThemeSettings?.showHide
    ? theme.modernThemeSettings?.showHide.showSectionTitles
    : true;
  return (
    <Box sx={{ marginTop: "40px" }}>
      <span />
      {showSectionTitles ? <SectionTitle>Loyalty</SectionTitle> : null}
      <Grid container spacing={"20px"} sx={{ position: "relative" }}>
        <OAProductsList
          productName={productName}
          productImage={productImage}
          variantName={variantName}
          mobilePreview={mobilePreview}
        />
        <OAReferralCard mobilePreview={mobilePreview} />
      </Grid>
    </Box>
  );
};
