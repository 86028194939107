import { prefixClassName } from "@smartrr/shared/components/primitives/index";
import { ICustomerPortalThemeForm, SECTION } from "@smartrr/shared/entities/CustomerPortalTheme";
import { typedLocalize } from "@smartrr/shared/utils/sharedTranslations/localize";
import React from "react";
import styled from "styled-components";

import { Navigation } from "./constants";

interface Props {
  themeValues: ICustomerPortalThemeForm;
  mobilePreview: boolean;
}
const Header = styled.h1`
  font-size: 42px;
  font-weight: 600;
  letter-spacing: -1px;
  margin-top: 44px;
`;

interface IHeaderProps {
  background?: string;
}

const HeaderWrapper = styled.div<IHeaderProps>`
  background: ${props => props.background};
  padding-bottom: 20px;
  text-align: center;
  & [class*="customer-header"] {
    padding-top: 20px;
    & > * {
      margin-bottom: 15px;
    }
    & [class*="customer-header-email"] {
      font-weight: 400;
      font-size: 14px;
    }

    & [class*="customer-since"] {
      font-size: 10px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
`;

export function AquariusHeader({ themeValues, mobilePreview }: Props): JSX.Element {
  const sectionOrder = themeValues.modernThemeSettings?.sectionsOrder;
  const currMonthYear = new Date().toLocaleString("en", { month: "long", year: "numeric" });
  const showPortalMenu = themeValues.modernThemeSettings?.showHide
    ? themeValues.modernThemeSettings?.showHide.showPortalMenu
    : true;

  const sections: { title: string; url: string; index: number }[] = [
    ...(sectionOrder &&
    sectionOrder.includes(SECTION.SUBSCRIPTION) &&
    themeValues.modernThemeSettings?.sections.subscriptions.display
      ? [
          {
            title: typedLocalize("header.tabs.subscriptions"),
            url: "#",
            index: sectionOrder.indexOf(SECTION.SUBSCRIPTION),
          },
        ]
      : []),
    ...(sectionOrder &&
    sectionOrder.includes(SECTION.ORDERHISTORY) &&
    themeValues.modernThemeSettings?.sections.orderHistory.display
      ? [
          {
            title: typedLocalize("header.tabs.orderHistory"),
            url: "#",
            index: sectionOrder.indexOf(SECTION.ORDERHISTORY),
          },
        ]
      : []),
    ...(sectionOrder &&
    sectionOrder.includes(SECTION.SETTINGS) &&
    themeValues.modernThemeSettings?.sections.settings.display
      ? [
          {
            title: typedLocalize("header.tabs.settings"),
            url: "#",
            index: sectionOrder.indexOf(SECTION.SETTINGS),
          },
        ]
      : []),
    ...(sectionOrder &&
    sectionOrder.includes(SECTION.LOYALTY) &&
    themeValues.modernThemeSettings?.sections.rewards &&
    themeValues.modernThemeSettings?.sections.rewards.display
      ? [
          {
            title: typedLocalize("header.tabs.loyalty"),
            url: "#",
            index: sectionOrder.indexOf(SECTION.LOYALTY),
          },
        ]
      : []),
    ...(sectionOrder &&
    sectionOrder.includes(SECTION.TRENDING) &&
    themeValues.modernThemeSettings?.sections.trending &&
    themeValues.modernThemeSettings?.sections.trending.display
      ? [
          {
            title: typedLocalize("header.tabs.trending"),
            url: "#",
            index: sectionOrder.indexOf(SECTION.TRENDING),
          },
        ]
      : []),
    ...(sectionOrder &&
    sectionOrder.includes(SECTION.INSTAGRAM) &&
    themeValues.modernThemeSettings?.sections.instagram &&
    themeValues.modernThemeSettings?.sections.instagram.display
      ? [
          {
            title: typedLocalize("header.tabs.social"),
            url: "#",
            index: sectionOrder.indexOf(SECTION.INSTAGRAM),
          },
        ]
      : []),
  ]
    .map((s, i) => {
      return s;
    })
    .sort((a, b) => a?.index! - b?.index!);

  return (
    <HeaderWrapper background={themeValues.modernPageBackground}>
      <div className={prefixClassName("customer-header")}>
        {!!themeValues.modernThemeSettings.welcomeInfo.customerFirstName && (
          <Header>{themeValues.modernThemeSettings.welcomeInfo.welcomeMessage}, John </Header>
        )}
        {!!themeValues.modernThemeSettings.welcomeInfo.customerEmail && <p>hello@smartrr.com</p>}
        {!!themeValues.modernThemeSettings.welcomeInfo.customerSinceDate && (
          <p
            style={{
              margin: mobilePreview ? 0 : "",
              fontSize: 10,
              fontWeight: 400,
              textTransform: "uppercase",
            }}
          >
            Customer Since {currMonthYear}
          </p>
        )}
      </div>
      {showPortalMenu ? (
        <Navigation background={themeValues.modernMenuBackground} isMobile={mobilePreview}>
          <ul style={{ padding: 0 }}>
            {sections.map((e: any) => {
              return (
                <li key={e.title}>
                  <a href={e.url}>{e.title}</a>
                </li>
              );
            })}
            {!mobilePreview && (
              <li>
                <a href="#">Log Out</a>
              </li>
            )}
          </ul>
        </Navigation>
      ) : null}
    </HeaderWrapper>
  );
}
