import Card, { CardProps } from "@mui/material/Card";
// import styled from "styled-components";
import { styled } from "@mui/material/styles";
import { prefixClassName } from "@smartrr/shared/components/primitives/index";
import React from "react";

const CardWrapper = styled(Card)`
  &.MuiCard-root {
    background-color: ${props => props.theme.modernCardBackground};
    border: solid;
    border-color: ${props => props.theme.modernCardBorderColor};
    border-radius: ${props => `${props.theme.modernCardRadius}px`};
    border-width: ${props => `${props.theme.modernCardBorder}px`};
    box-shadow: none;
    padding: 20px;
  }
`;

export const OACard = ({ ...props }: CardProps): JSX.Element => {
  return (
    <CardWrapper className={prefixClassName("card")} {...props}>
      {props.children}
    </CardWrapper>
  );
};
