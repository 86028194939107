import { Box, Grid, Stack, styled, useTheme } from "@mui/material";
import { TrendingHeart } from "@smartrr/shared/icons/Aquarius/TrendingHeart";
import { typedLocalize } from "@smartrr/shared/utils/sharedTranslations/localize";
import { truncate } from "lodash";
import React from "react";

import { OAButton } from "./OAButton";
import { OACard } from "./OACard";
import { OverflowGrid, StyledImageWrapper, VerticalProductWrapper } from "./OAStyles";
import { SectionTitle } from "./Settings";

interface ITrendingProps {
  mobilePreview: boolean;
  previewProduct?: {
    title: string;
    img: string;
  };
}

const SubscriptionsImg = styled("img")<{ borderRadius: number }>`
  border-radius: ${props => props.borderRadius}px;
`;

const Header = styled("h4")<{ mobilePreview: boolean }>`
  color: ${props => props.theme.modernSmallFontColor};
  font-size: ${props => (props.mobilePreview ? 20 : 26)}px;
  font-weight: ${props => props.theme.modernSmallFontWeight};
  letter-spacing: ${props => props.theme.modernSmallFontLetterSpacing}px;
  line-height: ${props =>
    props.mobilePreview ? props.theme.modernSmallFontLineHeight : props.theme.modernMediumFontLineHeight}px;
  margin: 0;
  margin-bottom: 15px;
  margin-top: ${props => (props.mobilePreview ? 15 : 0)}px;
`;

const Body = styled("p")`
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 20px;
  margin: 0px;
`;

export const Trending = ({ mobilePreview, previewProduct }: ITrendingProps) => {
  const theme = useTheme();
  const trendingSectionThemeData = theme.modernThemeSettings?.sections.trending;

  const showSectionTitles = theme.modernThemeSettings?.showHide
    ? theme.modernThemeSettings?.showHide.showSectionTitles
    : true;

  SubscriptionsImg.defaultProps = {
    src: previewProduct?.img,
  };

  return (
    <Box>
      <span className="anchor" id="trending" />
      {showSectionTitles ? <SectionTitle>{typedLocalize("trending.title")}</SectionTitle> : null}
      <OACard sx={{ padding: "20px 40px !important" }}>
        <Grid container display="flex" direction={mobilePreview ? "column" : "row"}>
          <Grid item md={7} display="flex" alignItems="center">
            <Grid
              container
              display="flex"
              direction="row"
              spacing={mobilePreview ? "15px" : "30px"}
              margin="0 auto"
              justifyContent={mobilePreview ? "center" : "start"}
              alignItems="center"
            >
              <Grid
                item
                sx={{
                  display: "block !important",
                  background: `url(${trendingSectionThemeData?.imgUrl})`,
                  height: "150px",
                  width: "150px",
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "137px",
                  position: "relative",
                  "& svg": {
                    position: "absolute",
                    bottom: "0px",
                    right: "0px",
                  },
                }}
              >
                {!!trendingSectionThemeData?.showHeart && <TrendingHeart />}
              </Grid>

              <Grid
                item
                md={8}
                direction="column"
                justifyContent="center"
                textAlign={mobilePreview ? "center" : "start"}
                paddingTop="0px !important"
              >
                <Header mobilePreview={mobilePreview}>{trendingSectionThemeData?.heading}</Header>
                <p style={{ opacity: "80%" }}>{trendingSectionThemeData?.caption}</p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={5} sx={{ width: "100%", marginTop: "15px" }}>
            {" "}
            <OverflowGrid container justifyContent={"center"}>
              <VerticalProductWrapper item>
                <StyledImageWrapper size={"large"}>
                  <Box sx={{ position: "relative", height: "90px", width: "90px" }}>
                    <SubscriptionsImg borderRadius={theme.modernImageBorderRadius || 0} style={{ height: 90 }} />
                  </Box>
                </StyledImageWrapper>
                <OAButton>Add</OAButton>

                <React.Fragment>
                  <Body>{truncate(previewProduct?.title)}</Body>
                  <Stack direction="row" spacing="3px" sx={{ fontSize: "12px" }}>
                    <span>$0.00</span>
                  </Stack>
                </React.Fragment>
              </VerticalProductWrapper>
            </OverflowGrid>
          </Grid>
        </Grid>
      </OACard>
    </Box>
  );
};
