import { Icon } from "@shopify/polaris";
import { ArrowDownMinor, ArrowUpMinor } from "@shopify/polaris-icons";
import { ICustomerPortalThemeForm } from "@smartrr/shared/entities/CustomerPortalTheme";
import { FormikErrors } from "formik";
import React, { useState } from "react";
import styled from "styled-components";

import { AccordionOpenIcon } from "@vendor-app/app/_sharedComponents/Icons";

import { Eye } from "../sections/styles";

const AccordionContainer = styled.div`
  width: 100%;
`;

const EmbeddedAccordionHeader = styled.div`
  align-items: center;
  color: #202223;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  span {
    margin-right: 2px;
  }
`;

const Title = styled.div`
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

const ClosedArrow = styled.span`
  svg {
    transform: rotate(-90deg);
  }
`;

const AccordionContent = styled.div`
  padding-left: 1.2rem;
`;

const IconsContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-right: -10px;
  span {
    margin-right: 5px;
    margin-top: 10px;
  }
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const TitleContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

interface IAccordionProps {
  children: any;
  title: string;
  setThemeFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ICustomerPortalThemeForm>>;
  themeValue?: boolean;
  themeValueName?: string;
  changeOrder?(index: number, direction: string): void;
  themeValues?: ICustomerPortalThemeForm;
  index?: number;
}

export const EmbeddedAccordion = ({
  children,
  title,
  setThemeFieldValue,
  themeValue,
  themeValueName,
  changeOrder,
  themeValues,
  index,
}: IAccordionProps) => {
  const [isActive, setIsActive] = useState<Boolean>(false);

  return (
    <AccordionContainer>
      <React.Fragment>
        <EmbeddedAccordionHeader>
          <span>
            {isActive ? (
              <span onClick={() => setIsActive(!isActive)}>{AccordionOpenIcon()}</span>
            ) : (
              <ClosedArrow onClick={() => setIsActive(!isActive)}>{AccordionOpenIcon()}</ClosedArrow>
            )}
          </span>
          <TitleContainer>
            <Title>{title}</Title>
            <IconsContainer>
              <Eye value={themeValue!} valueName={themeValueName!} setThemeFieldValue={setThemeFieldValue!} />
              {!!changeOrder && !!themeValues && (
                <React.Fragment>
                  <IconWrapper
                    onClick={() => {
                      changeOrder(index!, "DOWN");
                    }}
                  >
                    <Icon
                      source={ArrowDownMinor}
                      color={
                        index! === themeValues.modernThemeSettings.sectionsOrder.length - 1 ? "subdued" : "base"
                      }
                    />
                  </IconWrapper>
                  <IconWrapper
                    onClick={() => {
                      changeOrder(index!, "UP");
                    }}
                  >
                    <Icon source={ArrowUpMinor} color={index === 0 ? "subdued" : "base"} />
                  </IconWrapper>
                </React.Fragment>
              )}
            </IconsContainer>
          </TitleContainer>
        </EmbeddedAccordionHeader>

        {!!isActive && <AccordionContent>{children}</AccordionContent>}
      </React.Fragment>
    </AccordionContainer>
  );
};

export default EmbeddedAccordion;
