import styled from "styled-components";

export const ColorValues = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  div {
    margin-bottom: 5px;
  }
`;

export const WelcomeHeader = styled.p`
  color: #6d7175;
  margin-bottom: 10px;
  margin-top: 5px;
`;

export const TextFieldWrapper = styled.div`
  margin-top: 0;

  .Polaris-LegacyStack__Item {
    margin-top: 0;
  }
`;

export const ModernSettingsInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ActionsTitle = styled.span`
  font-weight: 600;
`;
