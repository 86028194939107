import { Page } from "@shopify/polaris";
import { IShopifyGetThemesResponse, getShopifyThemes } from "@smartrr/shared/shopifyRest/theme";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { loadCustomerPortalTheme } from "@vendor-app/app/_state/actionCreators/customerPortalTheme";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useRestClient } from "@vendor-app/app/AdminRoute/components/auth/RestProviderWrapper";

import { Theming } from "./sections/Main";
import { Spinner } from "../components/elements/Spinner";
const PageWrapper = styled.div`
  & > .Polaris-Page--fullWidth {
    padding: 0;
  }
  & > * .Polaris-Page__Content {
    margin-top: 0;
  }
`;

export function AdminThemingRoute(): JSX.Element {
  const restClient = useRestClient();
  const dispatch = useSmartrrVendorDispatch();
  const customerPortalThemeIsInitializing = useSmartrrVendorSelector(
    state => state.customerPortalTheme.isInitializing
  );
  const customerPortalTheme = useSmartrrVendorSelector(state => state.customerPortalTheme.customerPortalTheme);
  const { addToast } = useToast();

  const [shopifyThemes, setShopifyThemes] = useState<IShopifyGetThemesResponse["themes"]>([]);
  const [mainThemeLoading, setMainThemeLoading] = useState(true);

  const getMainThemeId = useCallback(async () => {
    const res = await getShopifyThemes(restClient);

    if (res.type === "success") {
      setShopifyThemes(res.body.themes || []);
    }

    if (res.type === "error" || !res.body.themes?.find(t => t.role === "main")) {
      addToast("Error getting main theme id");
      setMainThemeLoading(false);
      return;
    }

    setMainThemeLoading(false);
  }, []);

  useEffect(() => {
    getMainThemeId();
  }, []);

  useEffect(() => {
    if (!customerPortalThemeIsInitializing) {
      dispatch(loadCustomerPortalTheme());
    }
  }, []);

  return (
    <React.Fragment>
      <PageWrapper>
        <Page fullWidth>
          <React.Fragment>
            {customerPortalThemeIsInitializing || mainThemeLoading ? (
              <Spinner />
            ) : (
              <Theming themes={shopifyThemes} customerPortalTheme={customerPortalTheme} />
            )}
          </React.Fragment>
        </Page>
      </PageWrapper>
    </React.Fragment>
  );
}
