import { Grid, Link, Stack, Typography, useTheme } from "@mui/material";
import { AquariusIconButton } from "@smartrr/shared/customerPortal/components/AquariusIconButton";
import { ArrowForwardThick } from "akar-icons";
import React from "react";
import { EmailShareButton, TwitterShareButton, WhatsappShareButton } from "react-share";

import { OAButton } from "./OAButton";
import { OACard } from "./OACard";

interface Props {
  mobilePreview: boolean;
}

export const OAReferralCard = ({ mobilePreview }: Props) => {
  const SMSShareLink: React.FC<{ url: string | null; children?: React.ReactNode }> = ({ url, children }) => {
    if (!url) {
      return <div style={{ opacity: "0.6" }}>{children}</div>;
    }
    return <Link href={`sms:?&body=${url}`}>{children}</Link>;
  };

  const theme = useTheme();

  return (
    <Grid
      item
      xs={12}
      md={mobilePreview ? 12 : 4}
      sx={{
        transition: ".3s ease-in-out",
      }}
    >
      <OACard sx={{ height: "100%" }}>
        <Stack direction="column" spacing="15px" sx={{ width: "100%" }}>
          <Stack direction="row" alignItems="center" spacing="9px">
            <ArrowForwardThick strokeWidth={1.5} size={22} />
            <Typography
              sx={{
                fontSize: "20px",
                fontWeight: "normal",
                lineHeight: "28px",
                letterSpacing: 0,
              }}
              variant="h4"
            >
              Give %10, get $10
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "normal",
              lineHeight: "28px",
              letterSpacing: 0,
            }}
            variant="body1"
          >
            Get $10 each time a friend uses your referral link on their first purchase. Bonus — they&apos;ll get
            10% off their first order, too!
          </Typography>
          <OACard
            sx={{
              border: `${theme.modernSmallCardBorderWidth}px solid ${theme.modernSmallCardBorderColor} !important`,
              borderRadius: `${theme.modernSmallCardBorderRadius}px !important`,
              width: "100%",
            }}
          >
            <Stack direction="column" spacing="7px">
              <Stack direction="row" justifyContent="space-between" spacing="16px">
                <Typography variant="body1" sx={{ whiteSpace: "nowrap" }}>
                  Your friend gets
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    textAlign: "right",
                  }}
                  variant="body2"
                >
                  10% off
                </Typography>
              </Stack>
              <hr />
              <Stack direction="row" justifyContent="space-between" spacing="16px">
                <Typography variant="body1">You get</Typography>
                <Typography sx={{ fontWeight: 600, textAlign: "right" }} variant="body2">
                  10 points
                </Typography>
              </Stack>
            </Stack>
          </OACard>
          <OAButton filled onClick={() => null}>
            <Typography
              variant="body2"
              sx={{
                fontWeight: 500,
                color: `${theme.modernPrimaryButtonFontColor} !important`,
              }}
            >
              Copy my referral code
            </Typography>
          </OAButton>
          <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
            <EmailShareButton url={""} disabled={true}>
              <AquariusIconButton iconName="mail" size="small" />
            </EmailShareButton>
            <SMSShareLink url={""}>
              <AquariusIconButton iconName="chat" size="small" />
            </SMSShareLink>

            <TwitterShareButton url={""} disabled={true}>
              <AquariusIconButton
                iconName="twitter"
                size="small"
                sx={{ "& svg": { strokeWidth: "0px !important", fill: theme.modernIconsColor + "!important" } }}
              />
            </TwitterShareButton>

            <WhatsappShareButton url={""} disabled={true}>
              <AquariusIconButton
                iconName="whatsapp"
                size="small"
                sx={{ "& svg": { strokeWidth: "0px !important", fill: theme.modernIconsColor + "!important" } }}
              />
            </WhatsappShareButton>
          </Stack>
        </Stack>
      </OACard>
    </Grid>
  );
};
