import { styled as MUIStyled } from "@mui/material";
import MUIBox from "@mui/material/Box";
import styled from "styled-components";

interface TabStyles {
  preview: boolean;
}

interface IWrapperProps {
  background: string;
}

export const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const SideBar = styled.aside`
  background: #fff;
  border-right: 1px solid #e4e5e7;
  flex-shrink: 0;
  height: 100vh;
  margin-bottom: 16px;
  overflow: auto;
  position: sticky;
  top: 0;
  width: 300px;
`;

export const Content = styled.main`
  flex-grow: 1;
  max-width: 973px;
  padding: 16px;
`;

export const TabsContainer = styled.div`
  border-bottom: 1px solid #e4e5e7;
  color: #6d7175;
  display: flex;
  flex-direction: row;
`;

export const TabsWrapper = styled.div`
  cursor: pointer;
  width: 50%;
`;

export const Tab = styled.span`
  font-size: 14px;
  height: 52px;
  line-height: 52px;
  padding: 0 1rem;
  text-align: center;
`;

export const PreviewText = styled.div`
  color: #6d7175;
  padding: 15px 0 25px 0;
  text-align: center;
`;

export const ContainerGrid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-areas: "one one two";
`;

export const GridDiv1 = styled.div`
  grid-area: one;
  min-width: 620px;
`;

export const GridDiv2 = styled.div`
  grid-area: two;
`;

export const OverrideCard = styled.div`
  height: 445px;
  max-width: 370px;

  .Polaris-LegacyCard {
    height: 445px;
  }
`;
export const ModernOverrideCard = styled.div`
  font-size: 14px;
  font-weight: 400;
  .Polaris-LegacyCard {
    height: 325px;
    overflow: hidden;
  }
  line-height: 18px;
  margin-bottom: 25px;
  max-width: 528px;

  .Polaris-LegacyCard__Section {
    padding-top: 1rem;
  }
`;

export const ModernThemeContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconTab = styled.div`
  align-self: center;
  display: flex;
  height: 38px;
  overflow: hidden;
  width: 104px;
`;

export const ButtonWrapper = styled.div`
  align-self: center;
  .Polaris-Button {
    height: 36px;
  }
`;

export const RightTab = styled.div<TabStyles>`
  align-items: center;
  background: ${({ preview }) => (preview ? "#008060" : "white")};
  border: ${({ preview }) => (preview ? "1 px solid #008060" : "1px solid gray")};
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  cursor: pointer;
  height: 36px;
  justify-content: center;
  width: 52px;

  span {
    margin-top: 6px;
  }
  .Polaris-Icon--colorBase svg {
    fill: ${({ preview }) => (preview ? "white" : "var(--p-color-icon)")};
  }
`;

export const LeftTab = styled.div<TabStyles>`
  align-items: center;
  background: ${({ preview }) => (preview ? "#008060" : "white")};
  border: ${({ preview }) => (preview ? "1 px solid #008060" : "1px solid gray")};
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  cursor: pointer;
  height: 36px;
  justify-content: center;
  width: 52px;
  span {
    margin-top: 6px;
  }
  .Polaris-Icon--colorBase svg {
    fill: ${({ preview }) => (preview ? "white" : "var(--p-color-icon)")};
  }
`;

export const InnerWrapper = styled.div<IWrapperProps>`
  background-color: ${props => props.background};
  padding-bottom: 70px;
  & .anchor {
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;
  }
`;

export const MarketingBanner = MUIStyled(MUIBox)`
  display:flex;
  text-align:center;
  justify-content:center;
  align-items:center;
  background-color: ${props => props.theme.modernBannerColor};
  border-radius: ${props => props.theme.modernCardRadius}px;
  min-height:60px;
`;

export const IconWrapper = styled.span`
  align-items: end;
  display: flex;
  flex-direction: row;
  margin-top: 1.2rem;

  .Polaris-Icon {
    margin: 0 10px 0 0;
  }

  a {
    margin-left: 5px;
  }
`;

export const LinkWrapper = styled.span`
  align-items: end;
  .Polaris-Icon {
    display: inline-block;
    margin: 0;
  }
  .Polaris-Icon__Svg {
    margin-top: 5px;
  }
`;

export const BannerText = styled.p`
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 20px;
  margin: 0px;
`;
