import React from "react";

export const MasterCard = (): JSX.Element => {
  return (
    <svg width="35" height="21" viewBox="0 0 35 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H35V21H0V0Z" fill="white" />
      <path d="M15.0664 5.86719H20.2492V15.3669H15.0664V5.86719Z" fill="#FF5F00" />
      <path
        d="M15.3962 10.6182C15.3941 8.76429 16.2282 7.01219 17.6583 5.86769C15.2296 3.92099 11.7422 4.20449 9.64678 6.51869C7.55133 8.83324 7.55133 12.4015 9.64678 14.7157C11.7422 17.0302 15.2296 17.3137 17.6583 15.367C16.2289 14.2225 15.3945 12.4718 15.3959 10.6182H15.3962Z"
        fill="#EB001B"
      />
      <path
        d="M27.2419 10.6178C27.2419 12.9306 25.9469 15.0408 23.9075 16.0516C21.8677 17.062 19.4411 16.7964 17.6582 15.3666C19.0869 14.2214 19.9213 12.4704 19.9213 10.6171C19.9213 8.76353 19.0869 7.01249 17.6582 5.86729C19.4411 4.43754 21.8677 4.17189 23.9075 5.18269C25.9473 6.19314 27.2419 8.30329 27.2419 10.6161V10.6178ZM26.676 14.4451V14.1798H26.7533V14.1252H26.5573V14.1798H26.6413V14.4451H26.6763H26.676ZM27.0561 14.4451V14.1252H26.9969L26.928 14.3537L26.8587 14.1252H26.8062V14.4451H26.8489V14.205L26.9129 14.4132H26.9574L27.0218 14.205V14.4475L27.0561 14.4451Z"
        fill="#F79E1B"
      />
    </svg>
  );
};
