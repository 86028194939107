import { Box, Grid, Stack, useTheme } from "@mui/material";
import { CardLogo } from "@smartrr/shared/icons/Aquarius/cardLogos";
import React from "react";
import styled from "styled-components";

import { OAButton } from "./OAButton";
import { OACard } from "./OACard";

interface Props {
  mobilePreview: boolean;
}

export const PaymentCardTitle = styled.h4`
  color: #202223;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 28px;
  margin-bottom: 15px;
  margin-top: 0px;
`;

export const SectionTitle = styled.h2`
  color: #202223;
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 32px;
  margin-bottom: 20px;
`;

export const OASettings = ({ mobilePreview }: Props): JSX.Element => {
  const theme = useTheme();
  const showSectionTitles = theme.modernThemeSettings?.showHide
    ? theme.modernThemeSettings?.showHide.showSectionTitles
    : true;

  return (
    <div>
      {showSectionTitles ? <SectionTitle>Settings</SectionTitle> : null}
      <Grid container spacing={"20px"}>
        <React.Fragment>
          <Grid item xs={12} md={mobilePreview ? 12 : 4}>
            <OACard sx={{ height: "100%" }}>
              <PaymentCardTitle>Payment method</PaymentCardTitle>
              <Box sx={{ marginBottom: "15px" }}>
                <Stack direction="row" justifyContent="space-between">
                  <div>
                    <p>Visa</p>

                    <React.Fragment>
                      <p>{`•••• •••••• • 0000`}</p>
                      <p>Ex. 09/26</p>
                    </React.Fragment>
                  </div>
                  <React.Fragment>
                    <CardLogo cardName={"Visa"} />
                  </React.Fragment>
                </Stack>
              </Box>
            </OACard>
          </Grid>
        </React.Fragment>

        <Grid item xs={12} md={mobilePreview ? 12 : 4}>
          <OACard>
            <PaymentCardTitle>Login information</PaymentCardTitle>
            <p>hello@smartrr.com</p>
            <OAButton filled sx={{ marginTop: "20px" }}>
              Change email
            </OAButton>
            <OAButton filled>Change password</OAButton>
          </OACard>
        </Grid>
      </Grid>
    </div>
  );
};
