import React from "react";

export const AmericanExpress = (): JSX.Element => {
  return (
    <svg width="35" height="21" viewBox="0 0 35 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H35V21H0V0Z" fill="#006FCF" />
      <path
        d="M7.92108 8.99936L8.65958 10.7907H7.18748L7.92108 8.99936ZM23.2651 9.07426H20.4231V9.86316H23.2196V11.0462H20.4294V11.9261H23.2714V12.6317L25.2538 10.489L23.2707 8.25526L23.2651 9.07426ZM9.84188 6.84056H13.6555L14.5025 8.68716L15.2865 6.83496H25.1852L26.2142 7.97036L27.2782 6.83496H31.8254L28.4661 10.5114L31.7911 14.1654H27.1767L26.1477 13.03L25.0739 14.1654H8.93188L8.46078 13.03H7.38278L6.90958 14.1654H3.17578L6.31248 6.83496H9.58638L9.84118 6.84056H9.84188ZM18.111 7.86956H15.9739L14.5424 11.245L12.9912 7.86956H10.8702V12.4602L8.90318 7.86816H7.00128L4.72768 13.1301H6.21168L6.68348 11.994H9.16148L9.63328 13.1301H12.2296V9.37386L13.9005 13.1357H15.0366L16.6977 9.38576V13.1371H18.0893L18.1124 7.86746L18.111 7.86886V7.86956ZM27.0269 10.5114L29.4426 7.86956H27.7038L26.175 9.51666L24.698 7.86956H19.0714V13.1364H24.6175L26.1582 11.4774L27.6352 13.1364H29.4258L27.0276 10.5114H27.0269Z"
        fill="white"
      />
    </svg>
  );
};
