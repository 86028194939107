import { Card, MenuItem, Select, Stack } from "@mui/material";
import { styled as MUIStyled, useTheme } from "@mui/material/styles";
import { CircleChevronDown } from "akar-icons";
import React from "react";
import styled from "styled-components";

import { OAOrderCard } from "./OAOrderCard";
import { PaymentCardTitle, SectionTitle } from "./Settings";

const generateYearSelectMenuItems = () => {
  const menuItems: React.ReactNode[] = [];
  const currentYear = new Date().getUTCFullYear();
  for (let i = 0; i < currentYear - 2010; i++) {
    menuItems.push(
      <MenuItem value={currentYear - i} key={currentYear - i}>
        {currentYear - i}
      </MenuItem>
    );
  }
  return menuItems;
};

const CustomYearSelect = MUIStyled(Select)`
  background: ${props => props.theme.modernSecondaryButtonColor};
  border-radius: 20px;
  color: ${props => props.theme.modernSecondaryButtonFontColor};
  height: 30px;
  transition: 0.3s ease-in-out;
  width: 90px;

  & .MuiSelect-select {
    font-size: 14px;
    padding: 5px 15px;
  }

  .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${props => props.theme.modernSecondaryButtonBorderColor};
    border-radius: 20px;
  }

  :hover .MuiOutlinedInput-notchedOutline {
    border-color: #bbbbbb;
  }
`;

const OrderHistoryCard = MUIStyled(Card)`
  background: ${props => props.theme.modernCardBackground};
  border-color: ${props => props.theme.modernCardBorderColor};
  border-radius: ${props => props.theme.modernCardRadius}px;
  border-style: solid;
  border-width: ${props => props.theme.modernCardBorder}px;
  box-shadow: none;
  padding: 15px;
`;

const SelectWrapper = styled.div`
  svg {
    height: 18px;
    position: absolute;
    right: 15px;
    width: 18px;
  }
`;
interface Props {
  productImage: string;
  productName: string;
  mobilePreview: boolean;
}
export const OAOrderHistory = ({ productImage, productName, mobilePreview }: Props) => {
  const theme = useTheme();
  const showSectionTitles = theme.modernThemeSettings?.showHide
    ? theme.modernThemeSettings?.showHide.showSectionTitles
    : true;
  return (
    <div>
      <span className="anchor" id="order-history" />
      {showSectionTitles ? <SectionTitle>Order History</SectionTitle> : null}
      <OrderHistoryCard>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center" pr={2.25} mb={2}>
          <PaymentCardTitle style={{ marginBottom: "0px" }}>Past orders</PaymentCardTitle>
          <SelectWrapper>
            <CustomYearSelect IconComponent={() => <CircleChevronDown />} value={2022}>
              {generateYearSelectMenuItems()}
            </CustomYearSelect>
          </SelectWrapper>
        </Stack>
        <React.Fragment>
          <Stack
            direction={mobilePreview ? "column" : "row"}
            spacing="10px"
            paddingBottom="30px"
            sx={{ overflowX: "auto", height: mobilePreview ? "375px" : "unset" }}
          >
            <OAOrderCard productImage={productImage} productName={productName} />
            <OAOrderCard productImage={productImage} productName={productName} />
            <OAOrderCard productImage={productImage} productName={productName} />
          </Stack>
        </React.Fragment>
      </OrderHistoryCard>
    </div>
  );
};
