import { IconButton, SxProps, Theme, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import * as muiStyled from "@mui/material/styles";
import * as Icon from "akar-icons";
import React from "react";
import styled from "styled-components";

import { prefixClassName } from "@smartrr/shared/components/primitives";
import { ICustomerPortalTheme } from "@smartrr/shared/entities/CustomerPortalTheme";

interface IAquariusIconButtonProps {
  iconName?: IconButtonIcons;
  size: "small" | "medium" | "large";
  label?: string;
  onClick?: () => void | void | any;
  isActive?: boolean;
  tooltipTitle?: string;
  aquariusTheme?: ICustomerPortalTheme;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  noButton?: boolean;
  children?: React.ReactNode;
}

export declare type IconButtonIcons =
  | "shippingBox"
  | "clock"
  | "wallet"
  | "resume"
  | "skip"
  | "house"
  | "pause"
  | "stop"
  | "gift"
  | "calendarClock"
  | "canceled"
  | "gear"
  | "swap"
  | "calendar"
  | "unskip"
  | "pin"
  | "pencil"
  | "cart"
  | "trash"
  | "cart"
  | "person"
  | "star"
  | "share"
  | "mail"
  | "chat"
  | "instagram"
  | "twitter"
  | "facebook"
  | "whatsapp";

interface IStyledIconProps extends React.HTMLAttributes<HTMLDivElement> {
  aquariusTheme?: ICustomerPortalTheme;
  size: "small" | "medium" | "large";
}

const StyledIcon = styled(
  React.forwardRef<HTMLDivElement, IStyledIconProps>(({ aquariusTheme, ...props }: IStyledIconProps, ref) => (
    <div ref={ref} {...props} />
  ))
)`
  background-color: ${props => props?.aquariusTheme?.modernBadgeBackground};
  box-shadow: 0;
  height: ${props => (props.size === "small" ? "30px" : "40px")};
  margin: 0 auto;
  padding: ${props => (props.size === "small" ? "6px" : "9.5px")};
  text-align: center;
  transition: 0.3s ease-in-out;
  width: ${props => (props.size === "small" ? "30px" : "40px")};
  border-radius: 50%;

  &:hover {
    box-shadow: 0px 0px 10px #c9c9c9;
  }

  & svg {
    fill: transparent;
    height: ${props => (props.size === "small" ? "18px" : "21px")};
    stroke: ${props => props?.aquariusTheme?.modernIconsColor};
    stroke-width: 1.5;
    width: ${props => (props.size === "small" ? "18px" : "21px")};
  }
`;

const StyledIconButton = styled(
  React.forwardRef<HTMLButtonElement, IAquariusIconButtonProps>(
    ({ aquariusTheme, isActive, ...props }: IAquariusIconButtonProps, ref) => <IconButton ref={ref} {...props} />
  )
)`
  background-color: ${props => props?.aquariusTheme?.modernBadgeBackground};
  box-shadow: ${props => (props.isActive ? "0px 0px 10px #c9c9c9" : "none")}; //Active State
  height: ${props => (props.size === "small" ? "30px" : "40px")};
  margin: 0 auto;
  padding: ${props => (props.size === "small" ? "6px" : "9.5px")};
  text-align: center;
  transition: 0.3s ease-in-out;
  width: ${props => (props.size === "small" ? "30px" : "40px")};

  &:hover {
    box-shadow: 0px 0px 10px #c9c9c9;
  }

  & svg {
    height: ${props => (props.size === "small" ? "18px" : "21px")};
    stroke: ${props => props?.aquariusTheme?.modernIconsColor};
    width: ${props => (props.size === "small" ? "18px" : "21px")};
    fill: transparent;
    stroke-width: 1.5;
  }
`;

const Wrapper = muiStyled.styled("div")`
  color: ${props => props.theme.modernCaptionFontColor};
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content:center;
  text-align: center;
  white-space: nowrap;
`;

const StyledTooltips = muiStyled.styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.modernIconsColor,
    borderRadius: "8px",
    color: theme.modernPageBackground,
    fontSize: "12px",
  },
}));

export const AquariusIconButton = ({
  iconName,
  size,
  label,
  isActive,
  tooltipTitle,
  disabled = false,
  sx,
  noButton,
  ...props
}: IAquariusIconButtonProps): JSX.Element => {
  const Child = (): JSX.Element | null => {
    switch (iconName) {
      case "wallet": {
        return <Icon.Wallet />;
      }
      case "gift": {
        return <Icon.Gift />;
      }
      case "clock": {
        return <Icon.Clock />;
      }
      case "shippingBox": {
        return <Icon.ShippingBoxV2 />;
      }
      case "resume": {
        return <Icon.CircleTriangleRight />;
      }
      case "skip": {
        return <Icon.ArrowClockwise />;
      }
      case "house": {
        return <Icon.Home />;
      }
      case "pause": {
        return <Icon.Pause />;
      }
      case "stop": {
        return <Icon.Stop />;
      }
      case "calendarClock": {
        return <Icon.Schedule />;
      }
      case "canceled": {
        return <Icon.CircleX />;
      }
      case "gear": {
        return <Icon.Gear />;
      }
      case "swap": {
        return <Icon.ArrowRightLeft />;
      }
      case "calendar": {
        return <Icon.Calendar />;
      }
      case "unskip": {
        return <Icon.ArrowCounterClockwise />;
      }
      case "pin": {
        return <Icon.Pin />;
      }
      case "pencil": {
        return <Icon.Pencil />;
      }
      case "cart": {
        return <Icon.Cart />;
      }
      case "trash": {
        return <Icon.TrashCan />;
      }
      case "person": {
        return <Icon.Person />;
      }
      case "star": {
        return <Icon.Star />;
      }
      case "share": {
        return <Icon.ArrowForwardThick />;
      }
      case "mail": {
        return <Icon.Envelope />;
      }
      case "chat": {
        return <Icon.ChatBubble />;
      }
      case "instagram": {
        return <Icon.InstagramFill />;
      }
      case "twitter": {
        return <Icon.TwitterFill />;
      }
      case "facebook": {
        return <Icon.FacebookFill />;
      }
      case "whatsapp": {
        return <Icon.WhatsappFill />;
      }
      default: {
        return null;
      }
    }
  };

  if (noButton) {
    return (
      <Wrapper className={prefixClassName("icon-button")} sx={{ ...sx }}>
        <StyledTooltips title={tooltipTitle ?? ""} followCursor={true}>
          <StyledIcon size={size} aquariusTheme={muiStyled.useTheme()} {...props}>
            <Child />
          </StyledIcon>
        </StyledTooltips>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={prefixClassName("icon-button")} sx={{ ...sx }}>
      <StyledTooltips title={tooltipTitle ?? ""} followCursor={true}>
        <StyledIconButton
          disabled={disabled}
          size={size}
          isActive={isActive}
          aquariusTheme={muiStyled.useTheme()}
          {...props}
        >
          <Child />
        </StyledIconButton>
      </StyledTooltips>
      {!!label && (
        <span style={{ marginTop: "5px", lineHeight: "16px" }} dangerouslySetInnerHTML={{ __html: label }}></span>
      )}
    </Wrapper>
  );
};
