import {
  ICustomerPortalTheme,
  ICustomerPortalThemeForm,
  ICustomerPortalVariableType,
} from "@smartrr/shared/entities/CustomerPortalTheme";
import React from "react";

import { TypedDropdownProps, TypedRadioProps } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";

import AdvancedSettings from "./AdvancedSettings";

interface IProductSidebar {
  customerPortalTheme: ICustomerPortalTheme;
  themeValues: ICustomerPortalThemeForm;
  setThemeFieldValue: any;
  useThemeField: <V extends ICustomerPortalVariableType>(
    field: V
  ) => {
    Dropdown: React.MemoExoticComponent<
      (validationProps: TypedDropdownProps<ICustomerPortalThemeForm, V>) => JSX.Element
    >;
    Radio: React.MemoExoticComponent<
      (validationProps: TypedRadioProps<ICustomerPortalThemeForm, V>) => JSX.Element
    >;
  };
}

const ProductSidebar = ({
  themeValues,
  setThemeFieldValue,
  useThemeField,
  customerPortalTheme,
}: IProductSidebar) => {
  return (
    <AdvancedSettings
      customerPortalTheme={customerPortalTheme}
      themeValues={themeValues}
      setThemeFieldValue={setThemeFieldValue}
      useThemeField={useThemeField}
    />
  );
};

export default ProductSidebar;
