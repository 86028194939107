import { Box, Grid, styled as muiStyled } from "@mui/material";
import { prefixClassName } from "@smartrr/shared/components/primitives";
import styled from "styled-components";

export interface IHeaderProps {
  customerInfo: any;
  navOptions: { title: string; url: string }[];
  className?: string;
  id?: string;
  hasOrders: boolean;
}

interface INavigationProps {
  className?: string;
  isMobile: boolean;
  background: string;
}

export interface IInnerProps {
  customerInfo: any;
  sections?: { title: string; url: string }[];
  className?: string;
  id?: string;
  hasOrders: boolean;
  areOrdersLoading: boolean;
}

export interface IHeaderBGProps {
  background?: string;
}

export const Navigation = muiStyled("div")<INavigationProps>`
  width: 100%;
  align-items: center;
  background: ${props => props.theme.modernMenuBackground || "#FFF"};
  display: flex;
  height: 56px;
  justify-content: center;
  z-index: 2;
  & ul {
    margin: 0;
    -ms-overflow-style: none;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    padding: 15px;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    & li {
      font-size: ${props => props.theme.modernSubHeadingFontSize}px;
      font-weight: ${props => props.theme.modernSubHeadingFontWeight};
      list-style: none;
      margin: 15px;
      text-transform: uppercase;
      white-space: nowrap;
      & a {
        color: ${props => props.theme.modernSubHeadingFontColor};
        text-decoration: none;
        transition: 0.3s;
        &:visited,
        &:hover,
        &:focus {
          color: ${props => props.theme.modernSubHeadingFontColor};
        }
        &.active {
          text-decoration: underline;
        }
      }
    }
  }
  ${props =>
    props.isMobile &&
    `
    border-top:.5px solid ${props.theme.modernLineDividers};
    bottom: 1px;

    position: absolute;
    width: 373px;
  `}
`;

export const OrderWrapper = styled.div.attrs(() => ({
  className: prefixClassName("item-product-container"),
}))`
  width: max-content;
`;

export const NextOrderPriceLines = styled(Grid).attrs(props => ({
  ...props,
  className: prefixClassName("next-order-price"),
}))`
  margin: 15px 0px;

  & :last-child {
    font-weight: 700;
  }
`;

export const GridWrapper = styled.div`
  align-items: center;
  display: flex;
  position: relative;
`;

export const AddOnHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  height: 30px;
  justify-content: space-between;
`;

export const AddOnSectionWrapper = styled(Box)`
  position: relative;
`;

export const OverflowGrid = styled(Grid)`
  direction: ltr;
  height: max-content;
  max-height: 100%;
  width: max-content;
  will-change: transform;
`;

export const VerticalProductWrapper = styled(Grid).attrs(() => ({
  className: prefixClassName("addon-wrapper"),
}))`
  padding: 10px;
  transition: 0.3s ease-in-out;
  width: 110px;

  & > * {
    max-width: 90px;
  }
  & p {
    margin-bottom: 3px;
  }

  & button {
    margin: 7px 0;
    height: 30px;
  }

  & img,
  & .smartrr-portal-product-image-container,
  & .smartrr-portal-product-image-wrapper {
    height: 90px;
    width: 90px;
  }
  &[class*="disabled"] {
    pointer-events: none;
    &:hover {
      background-color: inherit;
    }
  }

  &[class*="list-item"],
  &[class*="list-item-selected"],
  &[class*="addon-selected"] {
    border-radius: 8px;
  }
  &[class*="list-item"]:hover,
  &[class*="list-item-selected"]:hover {
    background-color: rgba(244, 244, 244, 0.5);
  }

  &[class*="addon-selected"],
  &[class*="list-item-selected"] {
    background-color: #f4f4f4;
  }
`;

export const NoProducts = styled(Box).attrs(() => ({
  className: prefixClassName("addon-no-products"),
}))`
  align-items: center;
  background-color: rgba(244, 244, 244, 0.5);
  border-radius: 25px;
  display: flex;
  height: 278px;
  justify-content: center;
  margin-top: 15px;
  width: 100%;
`;
