import { Spinner } from "@shopify/polaris";
import {
  ICustomerPortalThemeForm,
  ICustomerPortalVariableType,
} from "@smartrr/shared/entities/CustomerPortalTheme";
import { FormikErrors } from "formik";
import React from "react";

import { TypedDropdownProps, TypedRadioProps } from "@vendor-app/app/_sharedComponents/TypedForm/useTypedForm";

import ModernPortalSettings from "./ModernPortalSettings";

interface Props {
  themeValues: ICustomerPortalThemeForm;

  setThemeFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<ICustomerPortalThemeForm>>;

  useThemeField: <V extends ICustomerPortalVariableType>(
    field: V
  ) => {
    Dropdown: React.MemoExoticComponent<
      (validationProps: TypedDropdownProps<ICustomerPortalThemeForm, V>) => JSX.Element
    >;
    Radio: React.MemoExoticComponent<
      (validationProps: TypedRadioProps<ICustomerPortalThemeForm, V>) => JSX.Element
    >;
  };
}

const ThemePageSidebar = ({ themeValues, setThemeFieldValue, useThemeField }: Props) => {
  const sectionsOrder = themeValues.modernThemeSettings.sectionsOrder;

  if (!sectionsOrder) {
    return <Spinner />;
  }

  return (
    <ModernPortalSettings
      themeValues={themeValues}
      setThemeFieldValue={setThemeFieldValue}
      useThemeField={useThemeField}
    />
  );
};

export default ThemePageSidebar;
