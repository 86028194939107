import { Badge, Card, Stack, Typography, styled as muiStyled } from "@mui/material";
import { AquariusBadge } from "@smartrr/shared/customerPortal/components/AquariusBadge";
import React from "react";

export const BadgeContainer = muiStyled("div")`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
`;

interface Props {
  productImage: string;
  productName: string;
}

export const AquariusBorderedCard = muiStyled(Card)`
  background: transparent;
  border: ${props => props.theme.modernSmallCardBorderWidth}px solid
    ${props => props.theme.modernSmallCardBorderColor};
  border-radius: ${props => props.theme.modernSmallCardBorderRadius}px !important;
  box-shadow: none;
  height: 100%;
  min-height: 150px;
  padding: 15px;
  transition: 0.3s ease-in-out;
  width: 285px;

  :hover {
    background: #f4f4f4;
    cursor: pointer;
  }
`;

const StyledImg = muiStyled("img")`
  width: 44px;
  height: 44px;
  border-radius: ${props => props.theme.modernSmallImageBorderRadius}px;
`;

export const OAOrderCard = ({ productImage, productName }: Props) => {
  return (
    <div>
      <AquariusBorderedCard>
        <Stack spacing={1} justifyContent="space-between" height="100%">
          <Stack direction="row" spacing="7px" alignItems="center" width="100%">
            <BadgeContainer>
              <Typography variant="body1" sx={{ fontSize: 12, lineHeight: "16px" }}>
                August 01, 2026
              </Typography>
              <AquariusBadge icon="clock" label={"Processing"} sx={{ padding: "2px 10px" }} />
            </BadgeContainer>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            px={0.5}
            pt={0.5}
            sx={{
              overflowX: "auto",
            }}
          >
            <Badge
              badgeContent={<Typography sx={{ fontSize: "12px !important", color: "#fff" }}>{1}</Typography>}
              overlap="circular"
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              variant="standard"
              sx={{
                width: "50px",
                "& .BaseBadge-badge": {
                  bgcolor: "#4B4B4B",
                },
              }}
            >
              <StyledImg src={productImage} />
            </Badge>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1" sx={{ fontSize: 12, lineHeight: "16px" }}>
              1 item
            </Typography>
            <Typography variant="body2" sx={{ fontSize: 12, lineHeight: "16px", fontWeight: 700 }}>
              $0.00
            </Typography>
          </Stack>
        </Stack>
      </AquariusBorderedCard>
    </div>
  );
};
