import { Stack, SxProps, Theme, useTheme } from "@mui/material";
import Chip, { ChipProps } from "@mui/material/Chip";
import * as Icon from "akar-icons";
import { startCase, toLower } from "lodash";
import React from "react";
import styled from "styled-components";

import { prefixClassName } from "@smartrr/shared/components/primitives/index";
import { ICustomerPortalTheme } from "@smartrr/shared/entities/CustomerPortalTheme";
import { PurchaseStateStatus } from "@smartrr/shared/entities/PurchaseState";
import { StarFilledSm } from "@smartrr/shared/icons/Aquarius/small";
import { typedLocalize } from "@smartrr/shared/utils/sharedTranslations/localize";

export declare type BadgeStates = "default" | "success" | "error" | "warning";
export declare type BadgeIcons =
  | "forwardLoop"
  | "gift"
  | "clock"
  | "shippingBox"
  | "circleMinus"
  | "starFilled"
  | "star"
  | "trophy"
  | "exclamationMark"
  | "cube"
  | "pin"
  | "check"
  | "pause"
  | "canceled"
  | "expired"
  | "pickup"
  | "circleX";

interface IBadgeProps {
  /**
   * Badge's background color - Optional
   * @default 'default'
   * */
  state?: BadgeStates;

  /** Subscription Status - Only to be used with purchaseStateStatus */
  status?: `${PurchaseStateStatus}` | PurchaseStateStatus;

  /** Icon placed within badge, left aligned ONLY - Optional */
  icon?: BadgeIcons;

  /** Content to be displayed within badge */
  label?: string;

  /** className passed to component when rendered */
  className?: string;

  sx?: SxProps<Theme>;

  /** Element to be displayed at the end of the badge */
  suffixIcon?: React.ReactNode;
}

interface IStyleBadgeProps extends ChipProps {
  aquariusTheme: ICustomerPortalTheme;
  spacing: "normal" | "short";
}

const Badge = styled(({ ...props }: IStyleBadgeProps) => <Chip {...props} />).attrs(props => ({
  ...props,
  className: prefixClassName("info-badge"),
}))<{ aquariusTheme: ICustomerPortalTheme }>`
  background-color: ${props => props.aquariusTheme.modernBadgeBackground};
  max-height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.aquariusTheme.modernBodyFontColor};
  padding: ${props => (props.spacing === "normal" ? "2px 10px" : "2px 4px 2px 10px")};
  font-family: ${props => props.aquariusTheme.modernBodyFontFamily};
  line-height: ${props => props.aquariusTheme.modernBodyFontLineHeight}px;
  letter-spacing: ${props => props.aquariusTheme.modernBodyFontLetterSpacing};

  & .MuiChip-label {
    padding: 0 0 0 4px;
  }

  & svg:not([class*="badge-contents"] svg) {
    height: 14px;
    stroke: ${props => props.aquariusTheme.modernIconsColor};
    width: 14px;
  }

  &[class*="success"] {
    background-color: ${props => props.aquariusTheme.modernActiveBadgeColor};
  }
  &[class*="error"] {
    background-color: ${props => props.aquariusTheme.modernCancelledBadgeColor};
  }
  &[class*="warning"] {
    background-color: ${props => props.aquariusTheme.modernPausedBadgeColor};
  }
`;

export const AquariusBadge = ({
  state = "default",
  status,
  icon,
  label,
  className,
  suffixIcon,
  ...props
}: IBadgeProps): JSX.Element => {
  let IconName = (): JSX.Element | null => {
    switch (icon) {
      case "forwardLoop": {
        return <Icon.ArrowClockwise />;
      }
      case "gift": {
        return <Icon.Gift />;
      }
      case "clock": {
        return <Icon.Clock />;
      }
      case "shippingBox": {
        return <Icon.ShippingBoxV2 />;
      }
      case "circleMinus": {
        return <Icon.CircleMinus />;
      }
      case "starFilled": {
        return StarFilledSm();
      }
      case "star": {
        return <Icon.Star />;
      }
      case "trophy": {
        return <Icon.Trophy />;
      }
      case "exclamationMark": {
        return <Icon.CircleAlert />;
      }
      case "cube": {
        return <Icon.Square />;
      }
      case "pin": {
        return <Icon.Pin />;
      }
      case "check": {
        return <Icon.CircleCheck />;
      }
      case "pause": {
        return <Icon.Pause />;
      }
      case "canceled": {
        return <Icon.CircleX />;
      }
      case "expired": {
        return <Icon.TriangleAlert />;
      }
      case "pickup": {
        return <Icon.ShoppingBag />;
      }
      case "circleX": {
        return <Icon.CircleX />;
      }
      default: {
        return null;
      }
    }
  };

  // If passing in purchaseStateStatus, Icon & color are predetermined
  switch (status) {
    case "ACTIVE": {
      label = typedLocalize("badgeStatus.active");
      state = "success";
      IconName = () => <Icon.CircleCheck />;
      break;
    }
    case "CANCELLED": {
      label = typedLocalize("badgeStatus.canceled");
      state = "error";
      IconName = () => <Icon.CircleX />;
      break;
    }
    case "EXPIRED": {
      label = typedLocalize("badgeStatus.expired");
      IconName = () => <Icon.TriangleAlert />;
      break;
    }
    case "FAILED": {
      label = typedLocalize("badgeStatus.failed");
      state = "error";
      IconName = () => <Icon.CircleX />;
      break;
    }
    case "PAUSED": {
      label = typedLocalize("badgeStatus.paused");
      state = "warning";
      IconName = () => <Icon.Pause />;
      break;
    }
    default: {
      break;
    }
  }

  const LabelEle = () => (
    <Stack
      className={prefixClassName("badge-contents")}
      gap="4px"
      direction="row"
      flexWrap="nowrap"
      alignItems="center"
    >
      <span>{label ?? startCase(toLower(status!)) ?? "Badge"}</span>
      {suffixIcon}
    </Stack>
  );
  return (
    <Badge
      spacing={suffixIcon ? "short" : "normal"}
      data-testid={`badge-status-${state}`}
      aquariusTheme={useTheme()}
      label={<LabelEle />}
      className={prefixClassName(`status-${state}`, className ?? "")}
      icon={!!IconName && <IconName />}
      {...props}
    />
  );
};
