import React from "react";

export const StarFilledSm = () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.53728 1.3165C5.69728 0.8945 6.30278 0.8945 6.46328 1.3165L7.49828 4.1835C7.57078 4.3735 7.75528 4.5 7.96128 4.5H10.5048C10.9748 4.5 11.1798 5.085 10.8103 5.3715L9.00028 7C8.9192 7.06233 8.85995 7.14874 8.83103 7.24683C8.8021 7.34492 8.80499 7.44965 8.83928 7.546L9.50028 10.3475C9.66128 10.7975 9.14028 11.184 8.74628 10.907L6.28778 9.347C6.20359 9.28783 6.10319 9.25608 6.00028 9.25608C5.89738 9.25608 5.79698 9.28783 5.71278 9.347L3.25428 10.907C2.86078 11.184 2.33928 10.797 2.50028 10.3475L3.16128 7.546C3.19557 7.44965 3.19846 7.34492 3.16954 7.24683C3.14061 7.14874 3.08136 7.06233 3.00028 7L1.19028 5.3715C0.820282 5.085 1.02628 4.5 1.49528 4.5H4.03878C4.13893 4.50033 4.23682 4.47025 4.31949 4.41374C4.40217 4.35722 4.46573 4.27693 4.50178 4.1835L5.53678 1.3165H5.53728Z"
        fill="#4B4B4B"
        stroke="#4B4B4B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
