import { appPrefixedHost } from "@smartrr/shared/constants";
import config from "@vendor-app/config";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

const REDIRECT_URL = `https://${appPrefixedHost}/admin/configure/theming/`;

export function instaAuthHandler() {
  window.open(
    `https://api.instagram.com/oauth/authorize?client_id=${config.instagram.clientId}&redirect_uri=${REDIRECT_URL}&scope=user_profile,user_media&response_type=code`
  );
}

export async function addInstagramUser(accessCode: string) {
  const res = await typedFrontendVendorApi.getReq(`/integration/social/instagram/token/${accessCode}`);
  if (res.type === "success") {
    return res.body;
  }
  console.error(`Error connecting account, please try again`);
}

export async function disconnectInstagramUser() {
  const res = await typedFrontendVendorApi.putReq(`/integration/social/instagram/delete`);
  if (res.type === "success") {
    location.reload();
  } else {
    console.error(`Error disconnecting account, please try again`);
  }
}

export async function getVendorInstagramData() {
  return typedFrontendVendorApi.getReq("/integration/social/instagram/posts");
}
