import { SxProps, Theme, useTheme } from "@mui/material";
import Chip from "@mui/material/Chip";
import { prefixClassName } from "@smartrr/shared/components/primitives/index";
import { PurchaseStateStatus } from "@smartrr/shared/entities/PurchaseState";
import { StarFilledSm } from "@smartrr/shared/icons/Aquarius/small";
import * as Icon from "akar-icons";
import _ from "lodash";
import React from "react";
import styled from "styled-components";

declare type State = "default" | "success" | "error" | "warning";
export declare type BadgeIcons =
  | "forwardLoop"
  | "gift"
  | "clock"
  | "shippingBox"
  | "circleMinus"
  | "starFilled"
  | "star"
  | "trophy"
  | "exclamationMark"
  | "cube"
  | "pin"
  | "check"
  | "pause"
  | "canceled"
  | "expired";

interface IBadgeProps {
  /**
   * Badge's background color - Optional
   * @default 'default'
   * */
  state?: State;

  /** Subscription Status - Only to be used with purchaseStateStatus */
  status?: `${PurchaseStateStatus}` | PurchaseStateStatus;

  /** Icon placed within badge, left aligned ONLY - Optional */
  icon?: BadgeIcons;

  /** Content to be displayed within badge */
  label?: string;

  sx?: SxProps<Theme>;
}
const Badge = styled(Chip).attrs(props => ({
  ...props,
  className: prefixClassName("info-badge"),
}))<{ aquariusTheme: Theme }>`
  background-color: ${props => props.aquariusTheme.modernBadgeBackground};
  max-height: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #202223;
  padding: 2px 10px;
  font-family: "Inter", sans-serif;

  & .MuiChip-label {
    padding: 0 0 0 4px;
  }

  & svg {
    height: 14px;
    stroke: #4b4b4b;
    width: 14px;
  }

  &[class*="success"] {
    background-color: ${props => props.aquariusTheme.modernActiveBadgeColor};
  }
  &[class*="error"] {
    background-color: ${props => props.aquariusTheme.modernCancelledBadgeColor};
  }
  &[class*="warning"] {
    background-color: ${props => props.aquariusTheme.modernPausedBadgeColor};
  }
`;

export const OABadge = ({ state = "default", status, icon, label, ...props }: IBadgeProps): JSX.Element => {
  let IconName = (): JSX.Element | null => {
    switch (icon) {
      case "forwardLoop": {
        return <Icon.ArrowClockwise />;
      }
      case "gift": {
        return <Icon.Gift />;
      }
      case "clock": {
        return <Icon.Clock />;
      }
      case "shippingBox": {
        return <Icon.ShippingBoxV2 />;
      }
      case "circleMinus": {
        return <Icon.CircleMinus />;
      }
      case "starFilled": {
        return StarFilledSm();
      }
      case "star": {
        return <Icon.Star />;
      }
      case "trophy": {
        return <Icon.Trophy />;
      }
      case "exclamationMark": {
        return <Icon.CircleAlert />;
      }
      case "cube": {
        return <Icon.Square />;
      }
      case "pin": {
        return <Icon.Pin />;
      }
      case "check": {
        return <Icon.CircleCheck />;
      }
      case "pause": {
        return <Icon.Pause />;
      }
      case "canceled": {
        return <Icon.CircleX />;
      }
      case "expired": {
        return <Icon.TriangleAlert />;
      }
      default: {
        return null;
      }
    }
  };

  // If passing in purchaseStateStatus, Icon & color are predetermined
  switch (status) {
    case "ACTIVE": {
      label = "Active";
      state = "success";
      IconName = () => <Icon.CircleCheck />;
      break;
    }
    case "CANCELLED": {
      label = "Canceled";
      state = "error";
      IconName = () => <Icon.CircleX />;
      break;
    }
    case "EXPIRED": {
      label = "Expired";
      IconName = () => <Icon.TriangleAlert />;
      break;
    }
    case "FAILED": {
      label = "Failed";
      state = "error";
      IconName = () => <Icon.CircleX />;
      break;
    }
    case "PAUSED": {
      label = "Paused";
      state = "warning";
      IconName = () => <Icon.Pause />;
      break;
    }
    default: {
      break;
    }
  }
  return (
    <Badge
      aquariusTheme={useTheme()}
      label={label || _.startCase(_.toLower(status!)) || "Badge"}
      className={prefixClassName(`status-${state}`)}
      icon={!!IconName && <IconName />}
      {...props}
    />
  );
};
